import { save, newspaper, readerOutline, send } from 'ionicons/icons';

export const helpContent = () => {
  return {
    descriptionScreen:"La pantalla 'Guía de Remisión Gerente' proporciona una visión general de las guías de remisión y pedidos del restaurante seleccionado. Las cuales se muestran agrupadas por cada uno de los fletes que se realizaran.",
    butonGuide:[
      {
        icon: null,
        descriptionButon: " Acceso a Detalles de Pedido",
        description: " Al oprimir clic en una guía de remisión la cual tenga el borde rojo se desplegara una pantalla la cual nos mostrara todos los detalles de dicha guía."
      },
      {
        icon: newspaper,
        descriptionButon: " Formulario de Jabas.",
        description: " Al oprimir clic en el botón 'formulario de jabas' se desplegará la pantalla del formulario para llenar la información de las jabas para el flete deseado."
      }
    ],
    userGuide:[
      {
        tittle: "Pantalla Guias de Remision Gerente",
        description: "En esta pantalla se muestra una lista de los fletes activos para el restaurante seleccionado, si expandimos un flete podremos observar la información de las guías de remisión asociadas a cada uno, si las guías de remisión se encuentran en estado en proceso de entrega se mostrará un borde rojo y podremos ingresar a los detalles de la misma para confirmar o generar la devolución de la misma."
      },
    ]
  };
}

export const helpContentDetails = () => {
  return {
    descriptionScreen:"La pantalla 'Detalles de Guías de Remisión Gerente' proporciona una vista detallada de los pedidos y detalles específicos de una guía de remisión. Los usuarios pueden inspeccionar los detalles del pedido, gestionar las cantidades recibidas y finalizar el proceso de aceptación o devolución.",
    butonGuide:[
      {
        icon: readerOutline,
        descriptionButon: " Ver Resumen",
        description: "EL Boton de 'Ver Resumen' en la parte inferior permite a los usuarios visualizar los detalles de aceptación o devolución en una ventana emergente."
      },
      {
        icon: send,
        descriptionButon: " Enviar y Guardar",
        description: "Un botón 'Enviar y Guardar' finaliza el proceso del pedido y almacena los detalles seleccionados. Esta acción confirma la aceptación o devolución de los artículos y finaliza el proceso de gestión."
      }
    ],
    userGuide:[
      {
        tittle: "Listado de Detalles y Cantidad Recibida",
        description: "La pantalla muestra un listado en forma de tabla con dos columnas: 'Detalles' y 'Cantidad Recibida'. La columna 'Detalles' presenta información detallada del pedido, como el Código SIR, Código ERP, Costo, Nombre del Artículo y Unidad. La columna 'Cantidad Recibida' contiene un input numérico que refleja la cantidad con la que se aceptó el envío."
      },
      {
        tittle: "Gestión de Cantidades Recibidas",
        description: "El input de 'Cantidad Recibida' muestra la cantidad aceptada originalmente en el placeholder. Los usuarios pueden ingresar una cantidad inferior a la indicada en el placeholder, pero no pueden ingresar una cantidad superior."
      },
      {
        tittle: "Detalles de Aceptación y Devoluciones Generales",
        description: "En la ventana emergente, se presentan tablas que resumen la aceptación o devolución de artículos. Si la cantidad recibida coincide con la indicada en el placeholder, los artículos se muestran en la tabla 'Detalles de Aceptación'. Si la cantidad recibida es inferior, los artículos restantes se listan en la tabla 'Devoluciones Generales'."
      },
      {
        tittle: "Comentario General de la Entrega",
        description: "Un elemento permite a los usuarios ingresar un comentario general relacionado con la entrega de los pedidos."
      },
    ]
  };
}

export const helpContentJabaForm = () => {
  return {
    descriptionScreen:"La pantalla 'Formulario de Jabas' permite a los usuarios ingresar y gestionar información sobre las cabeceras y detalles de las Jabas. Los usuarios pueden crear nuevas cabeceras de Jabas, agregar detalles a estas cabeceras y gestionar los detalles ingresados.",
    butonGuide:[
      {
        icon: save,
        descriptionButon: " Agregar o Editar Cabecera de Jaba",
        description: " Este botón se encarga de crear la cabecera de las Jabas en el caso de que no existan datos. Por el contrario si existen datos relacionados a esa Jabas se editara la información."
      }
    ],
    userGuide:[
      {
        tittle: "Formulario de Cabecera de Jabas",
        description: "En la sección inicial, los usuarios encuentran un formulario para ingresar información de la cabecera de las Jabas. Este formulario cuenta con los campos: Artículo, Temperatura, Código . Cabe a destacar que si al iniciar el formulario existen datos de la cabecera de Jabas se cargaran los datos por defecto y la acción del botón será editar en vez de crear"
      },
      {
        tittle: "Formulario de Detalles de Jabas",
        description: "En este formulario se listarán los tipos de jabas que ingreso el transportista al momento de llenar su reporte de jabas, el gerente deberá ingresar la cantidad que recibió o entrego de cada tipo de jaba."
      }
    ]
  };
}