import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import styles from "../Css/BackButton.module.scss";
import { chevronBackCircle } from 'ionicons/icons';

const BackButton = ({fn, value}) => {

    return (
        <div>
            <IonFab slot="fixed" vertical="bottom" className={styles.fabButton}>
                <IonFabButton onClick={()=>fn(value)}>
                    <IonIcon icon={chevronBackCircle}></IonIcon>
                </IonFabButton>
            </IonFab>
        </div>
    );
};

export default BackButton;