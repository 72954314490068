import React, {useState, useEffect} from "react";
import {IonGrid, IonInput, IonRow,  IonCol, IonIcon, useIonToast, 
IonSelect, IonSelectOption, IonAlert, IonButton, IonLoading} from '@ionic/react';
import { closeCircle, save, addCircle, checkmarkCircleOutline } from 'ionicons/icons';
import styles from "../Css/JabaForm.module.scss";
import CustomSelector from "../../../General/Components/CustomSelector";
import {createBagControl, createDetailBagControl, deleteDetailBagControl,
updateDetailBagControl, updateBagControl, getBagsArticles} from '../Services';

const JabaForm = ({details, headers, type, freightNumber, storeCode, idHeaderRoadMap}) => {
    const [showAlert, setShowAlert] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState(details);
    const [dataHead, setDataHead] = useState(headers);
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [present] = useIonToast();
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const bagsArticles = JSON.parse(sessionStorage.getItem('bagsArticles'));
        if(bagsArticles){
            setArticles(bagsArticles);
        } else {
            setLoading(true);
            getBagsArticles().then((response) => {
                setLoading(false);
                if(response.status){
                    sessionStorage.setItem('bagsArticles', JSON.stringify(response.data));
                    setArticles(response.data);
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        setDataHead({ ...dataHead, [e.target.id]: e.target.value });
    };
    
    const handleChangeDetails = (event) => {
        setFormValues({ ...formValues, [event.target.id]: event.detail.value });
    };

    const handleAddItem = () => {
        if(formValues.typeBag){
            if(validateJaba(formValues.typeBag)){
                formValues.deliveredQuantity = (formValues.deliveredQuantity)? formValues.deliveredQuantity : 0;
                formValues.receivedQuantity = (formValues.receivedQuantity)? formValues.receivedQuantity : 0;
                let body = {
                    idHeaderBagControl: dataHead.idHeaderBagControl,
                    typeBag: formValues.typeBag,
                    deliveredQuantity: formValues.deliveredQuantity,
                    receivedQuantity: formValues.receivedQuantity
                }
                setLoading(true);
                createDetailBagControl(body).then((response) => {
                    setLoading(false);
                    if (response.status) {
                        formValues.idDetaiilBagControl = response.data.idDetaiilBagControl;
                        setData((prevItems) => [...prevItems, formValues]);
                        setFormValues({});
                        document.getElementById('deliveredQuantity').value = '';
                        document.getElementById('receivedQuantity').value = '';
                    } else {
                        message(response.messages, false);
                    }
                });
            } else {
                message(['El tipo de jaba ya se encuentra agregado'], false);
            }
        } else {
            message(['Debe Seleccionar el tipo de jaba'], false);	
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let temperature = document.getElementById('temperature').value;
        let segureCode = document.getElementById('segureCode').value;
        if(dataHead.idArticle && temperature){
            setLoading(true);
            let data = {
                nameArticle: dataHead.nameArticle,
                typeHeader: type,
                securityCode: segureCode || '0',
                date: new Date().toLocaleDateString("en-CA"),
                temperature: temperature,
                freightNumber: freightNumber,
                storeCode: storeCode,
                idHeaderRoadMap: idHeaderRoadMap,
                idArticle: dataHead.idArticle,
                status: 1
            };
            if(dataHead.idHeaderBagControl){
                data.idHeaderBagControl = dataHead.idHeaderBagControl;
                updateBagControl(data).then((response) => {
                    setLoading(false);
                    setDataHead(data);
                    if (!response.status) {
                        message(response.messages, false);
                    }
                });
            } else {
                createBagControl(data).then((response) => {
                    setLoading(false);
                    let dataHead = (response.data)? response.data : data;
                    setDataHead(dataHead);
                    if (!response.status) {
                        message(response.messages, false);
                    }
                });
            } 
        } else {
            message(['Los campos artículo y temperatura'], false);	
        }
    };

    function alertOpen(item) {
        setShowAlert(true);
        setSelectedItem(item);
    }

    const deleteJaba = ()=>{
        if (selectedItem !== null) {
            setShowAlert(false);
            setLoading(true);
            deleteDetailBagControl(selectedItem.idDetaiilBagControl).then((response) => {
                setLoading(false);
                if(response.status){
                    const newData = data.filter(item => item.idDetaiilBagControl !== selectedItem.idDetaiilBagControl);
                    setData(newData);
                    setSelectedItem(null);
                } 
                message(response.messages, response.status);
            })
        }
    };

    const descriptionValue = (item)=>{
        switch (item) {
            case "1":
                return 'Jaba Produccion'
            case "2":
                return 'Jaba SD'
            case "3":
                return 'Jaba Vegetales'
            case "4":
                return 'Jaba Base'
            case "5":
                return 'Jaba Otros'
            default:
                return 'Jaba Otros'
        }
    }
    
    const onlyNumber = (e, item) => {
        document.getElementById(item).value = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    }

    function updateAmount(id){
        let deliveredQuantity = (document.getElementById('E'+id).value)? document.getElementById('E'+id).value : 0;
        let receivedQuantity = (document.getElementById('R'+id).value)? document.getElementById('R'+id).value : 0;
        let data = {
            idDetaiilBagControl: id,
            deliveredQuantity: parseInt(deliveredQuantity), 
            receivedQuantity: parseInt(receivedQuantity)
        }
        setLoading(true);
        updateDetailBagControl(data).then((response) => {
            setLoading(false);
            message(response.messages, response.status);
        });
    }

    function validateJaba(item){
        for(let detail in data){
            if(data[detail]['typeBag'] === item){
                return false;
            }
        }
        return true;
    }

    function message(messages, type){
        let icon = (type) ? checkmarkCircleOutline : closeCircle;
        let color = (type) ? 'success' : 'secondary';
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: color,
                icon: icon
            })
        ));
    }

    function setIdArticle(data){
        dataHead.nameArticle = data.description;
        dataHead.idArticle = data.id;
    }

    const printBody = () => {
        const rowBody = data.map((item, key) => (
                <IonRow key={key}>
                    <IonCol size='4' align='start'>{descriptionValue(item.typeBag)}</IonCol>
                    <IonCol size='3' align='center'>
                        <IonInput className={styles.inputDetail}
                            label="Outline input"
                            labelPlacement="floating"
                            fill="outline"
                            id={'E'+item.idDetaiilBagControl}
                            placeholder={item.deliveredQuantity}
                            type="number"
                            onBlur={() => updateAmount(item.idDetaiilBagControl)}
                            onInput={(e) => onlyNumber(e, 'E'+item.idDetaiilBagControl)}
                        />
                    </IonCol>
                    <IonCol size='3' align='center'>
                        <IonInput className={styles.inputDetail}
                            label="Outline input"
                            labelPlacement="floating"
                            fill="outline"
                            id={'R'+item.idDetaiilBagControl}
                            placeholder={item.receivedQuantity}
                            type="number"
                            onBlur={() => updateAmount(item.idDetaiilBagControl)}
                            onInput={(e) => onlyNumber(e, 'R'+item.idDetaiilBagControl)}
                        />
                    </IonCol>
                    <IonCol size='2' align='center'>
                        <IonIcon
                        className={styles.iconDelete}
                        icon={closeCircle}
                        onClick={() => alertOpen(item)}>
                        </IonIcon>
                    </IonCol>
                </IonRow>
        ));
        return rowBody;
    }

    const detailView = () => {
        return (
            <fieldset>
                <legend>Detalle Jabas</legend>
                <IonGrid>
                    <IonRow className={styles.header}>
                        <IonCol size='3' align='center'>Tipo</IonCol>
                        <IonCol size='4' align='center'># Recibida</IonCol>
                        <IonCol size='4' align='center'># Devuelta</IonCol>
                        <IonCol size='1' align='center'></IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size="4" align='start'>
                            <IonSelect 
                            placeholder="Seleccione"
                            value={formValues.typeBag}
                            id="typeBag"
                            onIonChange={(e)=>handleChangeDetails(e)}>
                                <IonSelectOption value="1">Jaba Produccion</IonSelectOption>
                                <IonSelectOption value="2">Jaba SD</IonSelectOption>
                                <IonSelectOption value="3">Jaba Vegetales</IonSelectOption>
                                <IonSelectOption value="4">Jaba Base</IonSelectOption>
                                <IonSelectOption value="5">Jaba Otros</IonSelectOption>
                            </IonSelect>
                        </IonCol>
                        <IonCol size="3" align='center'>
                            <IonInput className={styles.inputDetail}
                            label="C. Entregada"
                            placeholder="0"
                            type="number"
                            min="0"
                            id="deliveredQuantity"
                            onIonChange={(e)=>handleChangeDetails(e)}
                            onInput={(e) => onlyNumber(e, 'deliveredQuantity')}
                            />
                        </IonCol>
                        <IonCol size="3" align='center'>
                            <IonInput className={styles.inputDetail}
                            label="C. Recibida"
                            placeholder="0"
                            type="number" min="0"
                            id="receivedQuantity"
                            onIonChange={(e)=>handleChangeDetails(e)}
                            onInput={(e) => onlyNumber(e, 'receivedQuantity')}
                            />
                        </IonCol>
                        <IonCol size="2" align='center'>
                            <IonIcon className={styles.iconDelete}
                            icon={addCircle}
                            onClick={() => handleAddItem()}>
                            </IonIcon>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonGrid>
                    <IonRow className={styles.header}>
                        <IonCol size='3' align='start'>Detalle</IonCol>
                        <IonCol size='4' align='start'># Recibida</IonCol>
                        <IonCol size='4' align='start'># Devuelta</IonCol>
                        <IonCol size='1' align='center'></IonCol>
                    </IonRow>
                </IonGrid>
                <div className={ styles.content }>
                    <IonGrid>
                        {printBody()}
                    </IonGrid>
                </div>
            </fieldset>
        );
    }

    return (
        <div className={ styles.resultHeader }>
            <IonLoading isOpen={loading} message="Cargando..." />
            <fieldset>
                <legend>Cabecera Entrega Jabas - Transportista</legend>
                <IonGrid>
                <form onSubmit={handleSubmit}>
                <IonRow>
                    <IonCol size="6" >
                    <h2><b>Articulos</b></h2>
                        <CustomSelector 
                            tittle={"Articulos"}
                            data={articles}
                            identifier={'id'}
                            description={'descripcion'}
                            value={dataHead.idArticle || ""}
                            handleChange={setIdArticle}
                            returnObject={true}
                        />
                    </IonCol>
                    <IonCol size="6">
                        <h2><b>Temperatura</b></h2>
                        <IonInput
                        label="Temperatura"
                        placeholder="Temperatura"
                        type="number"
                        min="0"
                        max="100"
                        value={dataHead.temperature || ""}
                        id="temperature"
                        onChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size="6">
                        <h2><b>Código</b></h2>
                        <IonInput
                        label="Código de Seguridad"
                        placeholder="Código de Seguridad"
                        id="segureCode"
                        value={dataHead.securityCode || ""}
                        onChange={handleChange}/>
                    </IonCol>
                    <IonCol size="6"><br></br>
                        <IonButton type="submit" expand="block">
                            <IonIcon className={styles.ionIcon} icon={save}></IonIcon>
                        </IonButton>
                    </IonCol>
                </IonRow>
                </form>
                </IonGrid>
            </fieldset><br></br>
            {dataHead.idHeaderBagControl && (
                detailView()
            )}
            
        <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"¿Está seguro que desea eliminar el detalle de Jaba?"}
            buttons={[
                {
                    text: "Cancelar",
                    role: "cancel",
                },
                {
                    text: "Eliminar Detalle",
                    handler: () => {
                        deleteJaba();
                    }
                }
            ]}
        />
        </div>
    );
};

export default JabaForm;