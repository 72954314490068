import HttpClient from '../../services/HttpCilent';

export const getMenu = () =>{
    return new Promise((resolve) => {
        let data;
        HttpClient.get('/api/profilePermissions/getScreensByProfileCodeToMobile').then(resp => {
            if(resp.data.response){
                resp.data.data.forEach(function(elemento) {
                    if(elemento.url === "/guiaRemisions"){
                        elemento.showModalRestaurant = 0;
                    }else{
                        elemento.showModalRestaurant = 1;
                    }
                });
                data = {
                    status: resp.data.response,
                    data: resp.data.data
                };
            } else {
                data = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(data);
        }).catch(() => {
            const data = {
                status: false,
                messages:  ['Ocurrio un error de comunicación con el servidor, comuníquese con soporte'],
            };
            resolve(data);
        })
    })
}

export const getRestaurant = () =>{
    return new Promise((resolve) => {
        let data;
        HttpClient.get('/api/restaurants/getRestaurantsByUser').then(resp => {
            if(resp.data.response){
                const response = [];
                resp.data.data.forEach(function(elemento) {
                    const items = {
                        name: elemento.Cod_Restaurante,
                        type: "radio",
                        label: elemento.Cod_Tienda+'_'+elemento.Descripcion,
                        value: elemento.Cod_Restaurante+'_'+elemento.Localizacion_Api,
                    };
                    response.push(items);
                });
                data = {
                    status: resp.data.response,
                    data: response
                };
            } else {
                data = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(data); 
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicación con el servidor, comuníquese con soporte']
            };
            resolve(data);
        })
    })
}

export const updateToken = (data) =>{
    return new Promise((resolve) => {
        HttpClient.post('/api/home', data).then(resp => {
            if (resp.data.response) {
                sessionStorage.setItem("token", resp.data.data.data);
            }
            const data = {
                status: resp.data.response,
                messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
            };
            resolve(data);
        }).catch(error => {
            const data = {
                status: false,
                messages:  ['Ocurrio un error de comunicación con el servidor, comuníquese con soporte'],
            };
            resolve(data);
        })
    })
}