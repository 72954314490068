import { useState } from 'react';
import { IonHeader, IonToolbar, IonTitle, IonButton, 
IonIcon, IonLoading, IonAlert, useIonToast } from "@ionic/react";
import styles from "../Css/CustomHeader.module.scss";
import { helpCircle, home, exitOutline, closeCircle } from 'ionicons/icons';
import { useHistory } from "react-router-dom";
import ModalComponent  from './ModalComponent';
import { logout } from '../Services';

const CustomHeader = ({name, content, closeSession}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ showAlert, setShowAlert] = useState(false);
    const history = useHistory();
    const [present] = useIonToast();

    const redirectHome = () => {
        history.push("/home");
    }

    const openHelp = () => {
        setIsOpen(!isOpen);
    }

    const signOff = () => {
        setLoading(true);
        logout().then((response) => {
            if(response.status){
                sessionStorage.clear();
                history.push("/");
            }else {
                setLoading(false);
                response.messages.map((message)=> (
                    present({
                        message: message,
                        duration: 3000,
                        position: 'bottom',
                        color: 'danger',
                        icon: closeCircle
                    })
                ));
            }
        });
    }

    return (
        <IonHeader translucent={true}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonToolbar> 
                <IonButton fill="clear" slot="start" className={styles.butonHeader} onClick={redirectHome}>
                    <IonIcon icon={home} size="large"></IonIcon>
                </IonButton>
                <IonTitle text-center>{name}</IonTitle>
                <IonButton fill="clear" slot="end" className={styles.butonHeader} onClick={openHelp}>
                    <IonIcon icon={helpCircle} size="large"></IonIcon>
                </IonButton>
                {closeSession === '1' && (
                    <IonButton fill="clear" slot="end" className={styles.butonHeader} onClick={()=>setShowAlert(true)}>
                        <IonIcon icon={exitOutline} size="large"></IonIcon>
                    </IonButton>
                )}
            </IonToolbar>
            <ModalComponent open={isOpen} fn={openHelp} content={content}/>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={"¿Está seguro que desea cerrar sesión?"}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Aceptar",
                        handler: () => {
                            signOff();
                        }
                    }
                ]}
            />
        </IonHeader>
    );
};

export default CustomHeader;