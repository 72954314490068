import HttpClient from '../services/HttpCilent';

export const logout = () =>{
    return new Promise((resolve) => {
        let data;
        HttpClient.post('/api/logouts').then(resp => {
            data = {
                status: resp.data.response,
                messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
            };
            resolve(data);
        }).catch(() => {
            const data = {
                status: false,
                messages:  ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}