import React, {useState} from "react";
import {IonGrid, IonInput, IonRow,  IonCol, useIonToast, IonButton,
    IonIcon} from '@ionic/react';
import { readerOutline, closeCircle } from 'ionicons/icons';
import styles from "../Css/List.module.scss";
import ModalDetail from './ModalDetail';

const ListDetail = ({details, headers, fnBack, idDetailRoadMap, idGuide}) => {
    const [present] = useIonToast();
    const [refund, setRefund] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(!isOpen);
    }

    const onlyNumber = (e, item) => {
        let value =  e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
        if(value !== ''){
            if(value <= item.quantity){
                document.getElementById(item.codeSIR).value = value;
                item.amountEntered = value;
            } else {
                e.currentTarget.value = '';
                item.amountEntered = '';
                present({
                    message: 'La cantidad ingresada no puede ser mayor a la cantidad solicitada',
                    duration: 3000,
                    position: 'bottom',
                    color: 'danger',
                    icon: closeCircle
                })
            }
        }
    }

    const printBody = () => {
        const rowBody = details.map((item, key) => (
            <IonRow key={key} className={styles.colDetail}>
                <IonCol size='9'>
                {headers.map((header, keyD) => {
                    return (
                        <label key={keyD}>
                            {header.text} <b> {item[header.value]}</b> <br></br>  
                        </label>
                    );
                })}
                </IonCol>
                <IonCol size='3'>
                    <IonInput 
                        id={item.codeSIR}
                        placeholder={item.quantity}
                        onInput={(e) => onlyNumber(e, item)}
                    />
                </IonCol>
            </IonRow>
        ));

        return rowBody;
    }

    const save = () => {
        let refund = [];
        details.forEach(function(elemento) {
            if(elemento.amountEntered){
                if(elemento.amountEntered < elemento.quantity){
                    let item = elemento;
                    item.quantity = item.quantity - item.amountEntered;
                    item.subrecetCode = 1;
                    item.tax = 1;
                    delete item.amountEntered;
                    delete item.codeERP;
                    refund.push(item);
                }
            } else {
                elemento.amountEntered = elemento.quantity;
            }
        });

        setRefund(refund);
        openModal();
    }

    return (
        <>
            <IonGrid>
                <IonRow className={styles.headerDetail}>
                    <IonCol size='9' align='center'>Detalles</IonCol>
                    <IonCol size='3' align='center'>Cantidad Recibida</IonCol>
                </IonRow>
            </IonGrid>
            <div className={ styles.contentDetail }>
                <IonGrid>
                    {printBody()}
                </IonGrid>
                <IonButton expand="block" onClick={() => save()}>
                    Ver Resumen
                    <IonIcon className={styles.ionIcon} icon={readerOutline}></IonIcon>
                </IonButton>
            </div>
            <ModalDetail  
                fnBack={fnBack}
                open={isOpen}
                fn={openModal}
                details={details}
                refund={refund}
                idDetailRoadMap={idDetailRoadMap}
                idGuide={idGuide}
            />
        </>
    );
};

export default ListDetail;