export const formatInfoRoadMapBack = () => {
    return [
        { rename : 'id', name: 'idHeaderRoadMap'},
        { rename : 'hour_arrive_plant', name: 'plantArrivalTime'},
        { rename : 'hour_begin_charge', name: 'chargingStartTime'},
        { rename : 'hour_end_charge', name: 'finalLoadingTime'},
        { rename : 'hour_leave_plant', name: 'plantDepartureTime'},
        { rename : 'temp_begin_truck', name: 'initialTemperature'},
        { rename : 'temp_end_truck', name: 'finalTemperature'},
        { rename : 'lateral_door', name: 'sideDoor'},
        { rename : 'back_door', name: 'rearDoor'},
        { rename : 'route', name: 'route'},
        { rename : 'delivery_by', name: 'dispatchedBy'},
        { rename : 'provider_responsible', name: 'providerResponsible'}
    ];
}

export const formatHeaderBagControlBack = () => {
    return [
        { rename : 'id', name: 'idHeaderBagControl'},
        { rename : 'material_name', name: 'nameArticle'},
        { rename : 'type_header', name: 'typeHeader'},
        { rename : 'security_code', name: 'securityCode'},
        { rename : 'date', name: 'date'},
        { rename : 'local', name: 'storeCode'},
        { rename : 'temperature', name: 'temperature'},
        { rename : 'freight_number', name: 'freightNumber'},
        { rename : 'id_header_roadmap', name: 'idHeaderRoadMap'},
        { rename : 'id_article', name: 'idArticle'},
        { rename : 'status', name: 'status'},
    ];
}

export const formatDetailBagControlBack = () => {
    return [
        { rename : 'id', name: 'idDetaiilBagControl'},
        { rename : 'id_header', name: 'idHeaderBagControl'},
        { rename : 'type_bag', name: 'typeBag'},
        { rename : 'quantity_delivered', name: 'deliveredQuantity'},
        { rename : 'quantity_received', name: 'receivedQuantity'}
    ];
}