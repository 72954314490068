import { IonRow, IonCol, IonSelect, IonSelectOption, IonItem } from "@ionic/react";
import styles from "../Css/CustomSelector.module.scss";

const CustomSelector= ({tittle, data, identifier, description, value, handleChange, returnObject = false, legend = false}) => {
    const printData = () => {
        const pages = data.map((item) => (
            <IonSelectOption class={item[identifier]} value={item[identifier]} key={item[identifier]}>{item[description]}</IonSelectOption>
        ));
        return pages;
    };

    function getSelect(e){
        if(!returnObject){
            handleChange(e.detail.value);
        } else {
            let descripcion = document.getElementsByClassName(e.detail.value);
            if(descripcion && descripcion.length > 0){
                descripcion = descripcion[0].innerHTML;
            }
            handleChange({id: e.detail.value, description: descripcion});
        }
    }

    return (
        <div className={ styles.fieldPsA }>
            {<IonRow >
                <IonCol>
                    {legend === true && (
                        <legend className={ styles.legend }>{tittle}:</legend>
                    )}
                    
                    <IonItem className={ styles.itemPs }>
                        <IonSelect 
                            id={tittle}
                            interface="popover"
                            className={ styles.SelectPs }
                            onIonChange={(e)=>getSelect(e)}
                            value={value}
                            placeholder={tittle}
                        >
                            {printData()}
                        </IonSelect>
                    </IonItem>
                </IonCol>
            </IonRow>}
        </div>
    );
};

export default CustomSelector;