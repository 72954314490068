import { returnResponse, formatData } from '../../Helper/Helpers';
import HttpClient from '../../services/HttpCilent';
import { formatRoadMap, headersRoadMap, headersDetailRemisionGuide,
formatDetailRemisionGuide, formatHeaderBagControl, formatDetailBagControl,
formatGuideToPurchase, formatReasonReturn } from '../ManagerRemissionGuide/Data/Formats';
import { formatHeaderBagControlBack, formatDetailBagControlBack,
formatReturnHeaderBack, formatReturnDetailBack } from './Data/FormatsBack';

export const getHeaderRoadMap = () => {
    return new Promise((resolve) => {
        HttpClient.get('/api/roadMapHeaders/getByIdRestaurantJoinDetailRoadMapAndHeaderGuide'
        ).then(resp => { 
            let response;
            if (resp.data.response && resp.data.data !== null) {
                let data = formatCarrierRoadMap(resp.data.data, formatRoadMap());
                response = {
                    status: resp.data.response,
                    data: data.data,
                    freightNumber: data.freightNumbers,
                    headers: headersRoadMap()
                };
            } else {
                response = {
                    status: resp.data.response,
                    data: [],
                    freightNumber: [],
                    headers: [],
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        })
    })
}

export const detailRemisionGuides = (idGuide) => {
    return new Promise((resolve) => {
        HttpClient.get('api/detailRemisionGuides/getDetailByHeaderRemissionGuideIdAndArticle?'
            +'headerRemissionGuideId='+idGuide
        ).then(response => {
            resolve(returnResponse(response, formatDetailRemisionGuide(), headersDetailRemisionGuide()));
        })
    })
}

export const getBagControl = (codeStore, idHeaderRoadMap, freightNumber, type) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/bagControlHeaders/getByStoreCodeDateFreightTypeAndHeaderRoadmap?'
            +'store_code='+codeStore
            +'&id_header_roadmap='+idHeaderRoadMap
            +'&freight_number='+freightNumber
            +'&type_header='+type
            +'&status=1'
        ).then(resp => {
            let response;
            if (resp.data.response) {
                response = {
                    status: resp.data.response, 
                    data: formatData(resp.data.data[0], formatHeaderBagControl())
                };
            } else {
                response = returnResponse(resp);
            }
            resolve(response);
        })
    })
}


export const getDetailBagControl = (idHeaderBagControl) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/bagControlDetails/getByIdHeader?id_header='+idHeaderBagControl
            +'&status=1'
        ).then(response => {
            resolve(returnResponse(response, formatDetailBagControl()));
        })
    })
}

export const createBagControl = (data) => {
    let bagControl = formatData(data, formatHeaderBagControlBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/bagControlHeaders?type=basic'
            ,bagControl
        ).then(response => {
            resolve(returnResponse(response, formatHeaderBagControl()));
        })
    })
}

export const updateBagControl = (data) => {
    let bagControl = formatData(data, formatHeaderBagControlBack());
    return new Promise((resolve) => {
        HttpClient.put('/api/bagControlHeaders/'+bagControl.id+'?type=basic'
            ,bagControl
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const updateDetailBagControl = (data) => {
    let detailBagControl = formatData(data, formatDetailBagControlBack())
    return new Promise((resolve) => {
        HttpClient.put('api/bagControlDetails/'+detailBagControl.id+'?type=basic'
            ,detailBagControl
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const createDetailBagControl = (data) => {
    let detailBagControl = formatData(data, formatDetailBagControlBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/bagControlDetails?type=basic'
            ,detailBagControl
        ).then(response => {
            resolve(returnResponse(response, formatDetailBagControl()));
        })
    })
}

export const getReturnType = () =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/returnReasons/getAll').then(response => {
            resolve(returnResponse(response, formatReasonReturn()));
        })
    })
}

export const getRefundState = (idDetailRoadMap, status) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/carrierRoadMaps/finishDelivery/edit?'
            +'id='+idDetailRoadMap
            +'&status='+status
        ).then(response => {
            resolve(returnResponse(response));
        });
    })
}

export const createGuideToPurchase = (idGuide) => {
    let data = {header_remission_guide_id: idGuide};
    return new Promise((resolve) => {
        HttpClient.post('/api/referralGuideToPurchases?type=basic'
            ,data
        ).then(response => {
            resolve(returnResponse(response, formatGuideToPurchase()));
        })
    })
}

export const createReturn = (data, detail) => {
    let dataReturn = formatData(data, formatReturnHeaderBack());
    dataReturn.details = formatData(detail, formatReturnDetailBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/returns'
            ,dataReturn
        ).then(response => {
            resolve(returnResponse(response, formatGuideToPurchase()));
        })
    })
}

function formatCarrierRoadMap(response, format){
    let freightNumber = [];
    let data = {};
    response.forEach(function(element) {
        let item = {};
        format.map((header) => (
            item[header['rename']] = element[header['name']]
        ));
        if(!freightNumber.includes(item.freightNumber)){
            freightNumber.push(item.freightNumber);
        }
        if(!data[item.freightNumber]){
            data[item.freightNumber] = [];
        }
        data[item.freightNumber].push(item);
    });

    return {freightNumbers: freightNumber, data: data};
}