import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonLoading,
  useIonToast,
} from "@ionic/react";
import styles from "./Css/ManagerRemissionGuide.module.scss";
import CustomHeader from "../../General/Components/CustomHeader";
import BackButton from "../../General/Components/BackButton";
import {
  helpContent,
  helpContentDetails,
  helpContentJabaForm,
} from "./Data/Data";
import List from "./Components/List";
import ListDetail from "./Components/ListDetail";
import JabaForm from "../ManagerRemissionGuide/Components/JabaForm";
import {
  getHeaderRoadMap,
  detailRemisionGuides,
  getBagControl,
  getDetailBagControl
} from "./Services";
import { closeCircle } from "ionicons/icons";

function CarrierRemissionGuide() {
  const [name, setName] = useState("Guia de Remision Gerente");
  const [state, setState] = useState("header");
  const [details, setDetails] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [freightNumber, setFreightNumber] = useState([]);
  const [detailsList, setDetailsList] = useState([]);
  const [headersList, setHeadersList] = useState([]);
  const [detailsJaba, setDetailsJaba] = useState([]);
  const [headersJaba, setHeadersJaba] = useState([]);
  const [typeJabas, setTypeJabas] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeStore, setCodeStore] = useState("");
  const [idHeaderRoadMap, setIdHeaderRoadMap] = useState("");
  const [freight, setFreight] = useState("");
  const [present] = useIonToast();
  const [documentSelected, setDocumentSelected] = useState(helpContent());
  const [idDetailRoadMap, setIdDetailRoadMap] = useState('');
  const [idGuide, setIdGuide] = useState("");

  useEffect(() => {
    detailRoadMaps();
  }, []);

  function detailRoadMaps(){
    setLoading(true);
    getHeaderRoadMap().then((response) => {
      setLoading(false);
      setDetails(response.data);
      setHeaders(response.headers);
      setFreightNumber(response.freightNumber);
    });
  }


  const viewDetail = (idGuide, idDetailRoadMap) => {
    setLoading(true);
    detailRemisionGuides(idGuide).then((response) => {
      setLoading(false);
      if (response.status) {
        setIdDetailRoadMap(idDetailRoadMap);
        setDetailsList(response.data);
        setHeadersList(response.headers);
        setIdGuide(idGuide);
        setState("detail");
        setDocumentSelected(helpContentDetails());
        setName('Detalle Guia de Remision');
      } else {
        messageError(response.messages);
      }
    });
  };

  const viewJabaForm = (codeStore, idHeaderRoadMap, freightNumber) => {
    setLoading(true);
    setCodeStore(codeStore);
    setIdHeaderRoadMap(idHeaderRoadMap);
    setFreight(freightNumber);
    getBagControl(codeStore, idHeaderRoadMap, freightNumber, 2).then((response) => {
      if(response.status){
        setTypeJabas(2);
        setHeadersJaba(response.data);
        setDocumentSelected(helpContentJabaForm());
        getDetailBagControl(response.data.idHeaderBagControl).then((response) => {
          setDetailsJaba(response.data);
          setLoading(false);
          setState('jaba');
          setName('Formulario Jabas');
        });
      } else {
        getBagControl(codeStore, idHeaderRoadMap, freightNumber, 1).then((response) => {
          if(response.status){
            setTypeJabas(1);
            setDocumentSelected(helpContentJabaForm());
            setName('Formulario Jabas');
            setHeadersJaba(response.data);
            if(response.status){
                getDetailBagControl(response.data.idHeaderBagControl).then((response) => {
                    setDetailsJaba(response.data);
                    setLoading(false);
                    setState('jaba');
                });
            } else {
                setLoading(false);
                setState('jaba');
            }
          }else {
            setLoading(false);
            messageError(['El transportista encargado debe generar el control de jabas para poder realizar el registro.']);
          }
        })
      }
    });
  };

  function backView() {
    setState("header");
    setDocumentSelected(helpContent());
    setName('Guia de Remision Gerente');
  }

  function messageError(messages){
    messages.map((message) => (
        present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'secondary',
            icon: closeCircle
        })
    ));
  }

  function saveDetail(){
    setState("header");
    setDocumentSelected(helpContent());
    setName('Guia de Remision Gerente');
    detailRoadMaps();
  }
  
  return (
    <>
      <CustomHeader name={name} content={documentSelected} />
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonContent scrollY={false}>
        <div className={styles.center}>
          <IonCard className={styles.ionCard}>
            <IonCardContent>
              {state === "header" && (
                <List
                  details={details}
                  headers={headers}
                  freightNumber={freightNumber}                         
                  viewDetail={viewDetail}
                  viewJabaForm={viewJabaForm}
                />
              )}
              {state === "detail" && (
                <ListDetail
                  details={detailsList}
                  headers={headersList}
                  idDetailRoadMap={idDetailRoadMap}
                  fnBack={saveDetail}
                  idGuide={idGuide}
                />
              )}
              {state === "jaba" && (
                <JabaForm
                  details={detailsJaba}
                  headers={headersJaba}
                  type={typeJabas}
                  freightNumber={freight}
                  storeCode={codeStore}
                  idHeaderRoadMap={idHeaderRoadMap}
                />
              )}
            </IonCardContent>
          </IonCard>
        </div>
        {(state === "detail" || state === "jaba") && (
          <BackButton fn={backView} value="header" />
        )}
      </IonContent>
    </>
  );
}

export default CarrierRemissionGuide;
