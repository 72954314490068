import React, { useState, useEffect } from "react";
import styles from "../Css/ModalComponent.module.scss";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonCard,
  useIonToast,
  IonLoading,
  IonRow,
  IonCol,
  IonGrid,
  IonTextarea,
  IonIcon,
} from "@ionic/react";
import { closeCircle, send } from "ionicons/icons";
import CustomSelector from "../../../General/Components/CustomSelector";
import { getReturnType, getRefundState, createGuideToPurchase, createReturn } from "../Services";


const ModalDetail = ({ open, fn, details, refund, fnBack, idDetailRoadMap, idGuide}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [areas, setAreas] = useState([]);
  const [present] = useIonToast();
  const [comment, setComment] = useState("");

  useEffect(() => {
    setIsOpen(open);
    initialData();
  }, [open]);

  const initialData = () => {
    const tipoDevolucion = JSON.parse(sessionStorage.getItem('typeRefund'));
    if (tipoDevolucion) {
      setAreas(tipoDevolucion);
    } else {
      setLoading(true);
      getReturnType().then((response) => {
        setLoading(false);
        if (response.status) {
          setAreas(response.data);
          sessionStorage.setItem('typeRefund', JSON.stringify(response.data));
        } 
      });
    }
  };
 
  const printBody = () => {
    const rowBody = details.map((item, key) => (
      <IonRow key={key}>
        <IonCol size="3" align="center">{item.codeSIR}</IonCol>
        <IonCol size="6">{item.itemName}</IonCol>
        <IonCol size="3" align="center">{item.amountEntered}</IonCol>
      </IonRow>
    ));

    return rowBody;
  };

  const printRefund = () => {
    const rowBody = refund.map((item, key) => (
      <IonRow key={key}>
        <IonCol size="3" align="center">{item.codeSIR}</IonCol>
        <IonCol size="6">{item.itemName}</IonCol>
        <IonCol size="3" align="center">{item.quantity}</IonCol>
      </IonRow>
    ));

    return rowBody;
  };

  function handleEnviarGuardar(){
    if(refund.length > 0){
      if (!selectedValue || !comment) {
        messageError(['Los campos tipo y comentario de devolución son obligatorios.']);
      } else {
        acceptReturn();
      }
    }else {
      acceptGuide();
    }
  }

  function acceptReturn(){
    setLoading(true);
    createGuideToPurchase(idGuide).then((response) => {
      if(response.status){
        let data = {
          idDetailRoadMap: idDetailRoadMap,
          purchaseCode: response.data.movementId,
          providerId: 1,//response.data.providerId,
          date: response.data.date,
          status: 2,
          comment: comment,
          returnCode: selectedValue
        }
        createReturn(data, refund).then((response) => {
          if(response.status){
            getRefundState(idDetailRoadMap, 4).then((response) => {
              if (response.status) { 
                fnBack();  
              } else {
                setLoading(false);
                messageError(response.messages);
              }
            });
          }else {
            setLoading(false);
            messageError(response.messages);
          }
        })
      } else {
        setLoading(false);
        messageError(response.messages);
      }
    });
  }

  function acceptGuide(){
    setLoading(true);
    createGuideToPurchase(idGuide).then((response) => {
      if(response.status){
        getRefundState(idDetailRoadMap, 3).then((response) => {
          if (response.status) { 
            fnBack();  
          } else {
            setLoading(false);
            messageError(response.messages);
          }
        });
      } else {
        setLoading(false);
        messageError(response.messages);
      }
    });
  }

  function messageError(messages){
    messages.map((message) => (
        present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'secondary',
            icon: closeCircle
        })
    ));
  }

  return (
    <IonModal isOpen={isOpen}>
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonHeader>
        <IonToolbar>
          <IonTitle>Guía Nº {idGuide}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => fn()}>
              Cerrar
              <ion-icon slot="start" icon={closeCircle}></ion-icon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.content}>
        <div className={styles.center}>
          <IonCard>
            <IonGrid>
              <IonRow className={styles.header}>
                <IonCol size="12" align="center">Detalles Aceptados</IonCol>
              </IonRow>
              <IonRow className={styles.header}>
                <IonCol size="3" align="center">Código Producto</IonCol>
                <IonCol size="6" align="center">Descripción</IonCol>
                <IonCol size="3" align="center">Cantidad Recibida</IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid>
              {printBody()}
            </IonGrid>
            {refund.length > 0 && (
              <div>
                <IonGrid>
                  <IonRow className={styles.header}>
                    <IonCol size="12" align="center"> Devoluciones Generadas</IonCol>
                  </IonRow>
                  <IonRow className={styles.header}>
                    <IonCol size="3" align="center">Código Producto</IonCol>
                    <IonCol size="6" align="center">Descripción</IonCol>
                    <IonCol size="3" align="center">Cantidad Devuelta</IonCol>
                  </IonRow>
                </IonGrid>
                <IonGrid>
                  {printRefund()}
                  <div style={{ width: "100%" }}>
                    <IonRow>
                      <IonCol size="12">
                        <h2><b>Motivo de Devolución Gerente</b></h2>
                        <CustomSelector
                          tittle={'Motivo de Devolución Gerente'}
                          data={areas}
                          identifier={'returnCode'}
                          description={'description'}
                          value={selectedValue}
                          handleChange={(selectedValue) => setSelectedValue(selectedValue)}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="12">
                        <h2><b>Comentario Devolución</b></h2>
                        <IonTextarea
                          placeholder="Comentario Devolución"
                          className={styles.textArea}
                          value={comment}
                          onIonChange={(e) => setComment(e.target.value)}
                        ></IonTextarea>
                      </IonCol>
                    </IonRow>
                  </div>
                </IonGrid>
              </div>
            )}
            <IonGrid className='ion-text-center'>
              <IonButton onClick={()=>handleEnviarGuardar()}>
                Enviar y Guardar
                <IonIcon slot="end" icon={send}></IonIcon>
              </IonButton>
            </IonGrid>
          </IonCard>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ModalDetail;
