import { helpCircle, home, exitOutline } from 'ionicons/icons';

export const helpContent = () => {
  return { 
    descriptionScreen:"La siguiente pantalla se utiliza para visualizar todos los módulos sobre el cual el usuario tiene permisos.", 
    butonGuide:[
      {
        icon: home,
        descriptionButon: "(Home): ",
        description: "Al oprimir clic en el icono home se redireccionará la pantalla a la pantalla inicial de la aplicación."
      },
      {
        icon: helpCircle,
        descriptionButon: "(Ayuda): ",
        description: "Al oprimir clic en el icono de ayuda se despliega una nueva pantalla con toda la guía de uso de la pantalla actual en la cual nos encontremos."
      },
      {
        icon: exitOutline,
        descriptionButon: "(Cerrar Sesión): ",
        description: "Al oprimir clic en el icono de ayuda se despliega una ventana de alerta donde se nos preguntara si deseamos cerrar sesión en caso de darle ACEPTAR se cerrará la sesión y se redireccionará a la pantalla del Login de la aplicación, en caso de oprimir el botón CANCELAR permaneceremos en la pantalla actual."
      }
    ], 
    userGuide:[
      {
        tittle: "Buscar Menú",
        description: "Para buscar un módulo en particular deberá ingrese la letra o palabra que corresponda al módulo requerido y la aplicación automáticamente mostrará las coincidencias de la consulta realizada."
      },{
        tittle: "Ingreso Menú",
        description: "Al oprimir el ícono del módulo seleccionado en caso de que el usuario tenga mas de un restaurante asociado, se desplegara una lista con todos los restaurantes asociados al usuario en donde deberá escoger el que desea y oprimir el botón ACEPTAR para ingresar al módulo correspondiente, en caso de que el usuario solo tenga un restaurante asociado se ingresa al módulo correspondiente inmediatamente."
      }
    ]
  };  
}
