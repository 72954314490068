import React, { useEffect, useState } from 'react';
import styles from "../Css/Refund.module.scss";
import { IonButton, useIonToast, 
    IonLoading, IonRow, IonCol, IonGrid, IonAlert, IonIcon} from '@ionic/react';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import { getRefund, getRefundState, updateRoadMatReturns, getDetailRefund, updateReturns } from '../Services';

const Refund = ({orderRefund, returnDetial}) => {
    const [ loading, setLoading] = useState(false);
    const [ refund, setRefund] = useState([]);
    const [ typeRefund, setType] = useState('');
    const [ commenRefund, setComment] = useState('');
    const [ showAlert, setShowAlert] = useState(false);
    const [ messageAlert, setMessage] = useState('');
    const [ status, setStatus] = useState(0);
    const [ statusRefund, setStatusRefund] = useState(0);
    const [present] = useIonToast();

    useEffect(() => {
        setLoading(true);
        getRefund(orderRefund.idReturn, orderRefund.idRestaurant).then((response) => {
            if (response.status) {
                setType(response.data.reasonReturn);
                setComment(response.data.comment);
                getDetailRefund(orderRefund.idReturn, orderRefund.idRestaurant).then((response) => {
                    setLoading(false);
                    if(response.status){
                        setRefund(response.data);
                    } else {
                        messageError(response.messages);  
                    }
                });
            } else {
                setLoading(false);
                messageError(response.messages);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const printRefund = () => {
        const rowBody = refund.map((item, key) => (
            <IonRow key={key}>
                <IonCol size='3'  align='center'>{item.itemCode}</IonCol>
                <IonCol size='6'>{item.description}</IonCol>
                <IonCol size='3'  align='center'>{item.quantity}</IonCol>
            </IonRow>
        ));

        return rowBody;
    }

    function openAlert(type){
        if(type === 1){
            setMessage('¿Está seguro que desea rechazar la devolución?');
            setStatus(3);
            setStatusRefund(5);
        } else {
            setMessage('¿Está seguro que desea aceptar la devolución?');
            setStatus(2);
            setStatusRefund(3);
        }
        setShowAlert(true);
    }

    function updateRefund(){
        setLoading(true);
                updateRoadMatReturns(orderRefund.idRoadMapReturn, status).then((response)=>{
            if(response.status){
                updateReturns(orderRefund.idReturn, statusRefund, orderRefund.idRestaurant).then((response) => {
                    if(response.status){
                        getRefundState(orderRefund.idDetailRoadMap, 3).then((response) => {
                            setLoading(false);
                            if(response.status){
                                returnDetial();
                            } else {
                                messageError(response.messages);
                            }
                        });
                    } else {
                        setLoading(false);
                        messageError(response.messages);
                    }
                });
            } else {
                setLoading(false);
                messageError(response.messages);
            }
        });
    }

    function messageError(messages){
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    return (
        <div className={styles.content}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonGrid className={styles.items}>
                <IonRow className={styles.header}>
                    <IonCol size='12' align='center'> Devoluciones Generadas</IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size='12' align='center'>
                        <label><b>Guía No: </b> {orderRefund.idDetailRoadMap} </label>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size='12'>
                        <label><b>Tipo Devolución: </b> {typeRefund} </label>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size='12'>
                        <label><b>Comentario Devolución:</b> {commenRefund}</label>
                    </IonCol>
                </IonRow>
                <IonRow className={styles.header}>
                    <IonCol size='12' align='center'> Detalles</IonCol>
                </IonRow>
                <IonRow className={styles.header}>
                    <IonCol size='3' align='center'>Codigo Producto</IonCol>
                    <IonCol size='6' align='center'>Descripción</IonCol>
                    <IonCol size='3' align='center'>Cantidad Devuelta</IonCol>
                </IonRow>
                    {printRefund()}
                <IonRow>
                    <IonCol size='6' align='center'>
                        <IonButton onClick={()=>openAlert(1)}> 
                            Rechazar
                            <IonIcon slot="end" icon={closeCircle}></IonIcon>
                        </IonButton>
                    </IonCol>
                    <IonCol size='6' align='center'>
                        <IonButton onClick={()=>openAlert(2)}>
                            Aceptar
                            <IonIcon slot="end" icon={checkmarkCircle}></IonIcon>
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={messageAlert}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Aceptar",
                        handler: () => {
                            updateRefund();
                        }
                    }
                ]}
            />
        </div>
    );
};

export default Refund;
