import React, {useState, useEffect} from "react";
import {IonGrid, IonInput, IonRow,  IonCol, IonIcon, useIonToast,
IonCheckbox, IonButton, IonLoading} from '@ionic/react';
import { closeCircle, save, checkmarkCircleOutline } from 'ionicons/icons';
import styles from "../Css/JabaForm.module.scss";
import {createBagControl, createDetailBagControl, updateDetailBagControl,
updateBagControl, getBagControl, getDetailBagControl} from '../Services';

const JabaForm = ({details, headers, type, freightNumber, storeCode, idHeaderRoadMap}) => {
    const [data, setData] = useState(details);
    const [dataHead, setDataHead] = useState(headers);
    const [loading, setLoading] = useState(false);
    const [actionType, setActionType] = useState(type);
    const [ checked, setChecked ] = useState(false);
    const [present] = useIonToast();
    let executeDetail = true;

    useEffect(() => {
        if(type === 1){
            delete dataHead.idHeaderBagControl;
            delete dataHead.securityCode;
            const newDetail = data.map((item) => {
                delete item.idDetaiilBagControl
                return item;
            })
            setData(newDetail);
        } else if (type === 2 && executeDetail){
            searchDetail();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);

    const handleChange = (e) => {
        setDataHead({ ...dataHead, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let temperature = document.getElementById('temperature').value;
        let segureCode = document.getElementById('segureCode').value;
        if(temperature && segureCode){
            setLoading(true);
            let data = {
                nameArticle: dataHead.nameArticle,
                typeHeader: 2,
                securityCode: segureCode,
                date: new Date().toLocaleDateString("en-CA"),
                temperature: temperature,
                freightNumber: freightNumber,
                storeCode: storeCode,
                idHeaderRoadMap: idHeaderRoadMap,
                idArticle: dataHead.idArticle,
                truckLock: checked.toString(),
                status: 1
            };
            if(dataHead.idHeaderBagControl){
                data.idHeaderBagControl = dataHead.idHeaderBagControl;
                updateBagControl(data).then((response) => {
                    setLoading(false);
                    setDataHead(data);
                    if (!response.status) {
                        message(response.messages, false);
                    }
                });
            } else {
                createBagControl(data).then((response) => {
                    setLoading(false);
                    let dataHead = (response.data)? response.data : data;
                    setDataHead(dataHead);
                    if (!response.status) {
                        message(response.messages, false);
                    }else {
                        setActionType(2);
                    }
                });
            } 
        } else {
            message(['La temperatura y sello de seguridad es obligaorio'], false);	
        }
    };

    const descriptionValue = (item)=>{
        switch (item) {
            case "1":
                return 'Jaba Produccion'
            case "2":
                return 'Jaba SD'
            case "3":
                return 'Jaba Vegetales'
            case "4":
                return 'Jaba Base'
            case "5":
                return 'Jaba Otros'
            default:
                return 'Jaba Otros'
        }
    }
    
    const onlyNumber = (e, item) => {
        document.getElementById(item).value = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    }

    function updateAmount(item){
        if(actionType === 1){
            message(['Se debe guardar la cabecera para poder generar los detalles'], false);
        } else {
            item.deliveredQuantity = parseInt(document.getElementById('E'+item.typeBag).value);
            item.receivedQuantity = parseInt(document.getElementById('R'+item.typeBag).value);
            setLoading(true);
            if(item.idDetaiilBagControl){
                updateDetailBagControl(item).then((response) => {
                    setLoading(false);
                    message(response.messages, response.status);
                });
            } else {
                item.idHeaderBagControl = dataHead.idHeaderBagControl;
                createDetailBagControl(item).then((response) => {
                    setLoading(false);
                    if (response.status) {
                        item.idDetaiilBagControl = response.data.idDetaiilBagControl;
                    } else {
                        message(response.messages, false);
                    }
                    console.log(item);
                });
            }
        } 
    }

    function message(messages, type){
        let icon = (type) ? checkmarkCircleOutline : closeCircle;
        let color = (type) ? 'success' : 'secondary';
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: color,
                icon: icon
            })
        ));
    }

    function searchDetail(){
        executeDetail = false;
        setLoading(true);
        getBagControl(storeCode, idHeaderRoadMap, freightNumber, 1).then((response) => {
            getDetailBagControl(response.data.idHeaderBagControl).then((response) => {
                setLoading(false);
                processDetail(response.data);
            });    
        });
    }

    function processDetail(carrierDetail){
        if(details.length === 0){
            const newDetail = carrierDetail.map((item) => {
                delete item.idDetaiilBagControl
                return item;
            })
            setData(newDetail);
        } else {
            const newDetail = carrierDetail.map((item) => {
                delete item.idDetaiilBagControl;
                let response = item;
                // eslint-disable-next-line
                details.map((detail) => {
                    if(item.typeBag === detail.typeBag){
                        response = detail;
                    }
                })
                return response;
            });
            setData(newDetail);
        }
    }

    const printBody = () => {
        const rowBody = data.map((item, key) => (
                <IonRow key={key}>
                    <IonCol size='4' align='start'>{descriptionValue(item.typeBag)}</IonCol>
                    <IonCol size='4' align='center'>
                        <IonInput className={styles.inputDetail}
                            label="Outline input"
                            labelPlacement="floating"
                            fill="outline"
                            id={'E'+item.typeBag}
                            value={item.deliveredQuantity || ""}
                            type="number"
                            onBlur={() => updateAmount(item)}
                            onInput={(e) => onlyNumber(e, 'E'+item.typeBag)}
                        />
                    </IonCol>
                    <IonCol size='4' align='center'>
                        <IonInput className={styles.inputDetail}
                            label="Outline input"
                            labelPlacement="floating"
                            fill="outline"
                            id={'R'+item.typeBag}
                            value={item.receivedQuantity || ""}
                            type="number"
                            onBlur={() => updateAmount(item)}
                            onInput={(e) => onlyNumber(e, 'R'+item.typeBag)}
                        />
                    </IonCol>
                </IonRow>
        ));
        return rowBody;
    }

    const detailView = () => {
        return (
            <fieldset>
                <legend>Detalle Jabas</legend>
                <IonGrid>
                    <IonRow className={styles.header}>
                        <IonCol size='4' align='center'>Detalle</IonCol>
                        <IonCol size='4' align='center'># Devuelta</IonCol>
                        <IonCol size='4' align='center'># Recibida</IonCol>
                    </IonRow>
                </IonGrid>
                <div className={ styles.content }>
                    <IonGrid>
                        {printBody()}
                    </IonGrid>
                </div>
            </fieldset>
        );
    }

    return (
        <div className={ styles.resultHeader }>
            <IonLoading isOpen={loading} message="Cargando..." />
            <fieldset>
                <legend>Cabecera Jabas Devuelta - Gerente</legend>
                <IonGrid>
                <form onSubmit={handleSubmit}>
                <IonRow>
                    <IonCol size="12" align='center'>
                        <h2><b>Articulo:</b> {dataHead.nameArticle}</h2>
                    </IonCol>
                    <IonCol size="6">
                        <h2><b>Temperatura</b></h2>
                        <IonInput
                        label="Temperatura"
                        placeholder="Temperatura"
                        type="number"
                        min="0"
                        max="100"
                        value={dataHead.temperature || ""}
                        id="temperature"
                        onChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size="6">
                        <h2><b>Sello Seguridad</b></h2>
                        <IonInput
                        label="Sello Seguridad"
                        placeholder="Sello de Seguridad"
                        id="segureCode"
                        value={dataHead.securityCode || ""}
                        onChange={handleChange}/>
                    </IonCol>
                    <IonCol size="6" align='center'>
                        <h2><b>Candado Camión</b></h2>
                        <IonCheckbox alignment="center" onIonChange={(e) => setChecked(e.detail.checked)}/>
                    </IonCol>
                    <IonCol size="6"><br></br>
                        <IonButton type="submit" expand="block">
                            <IonIcon className={styles.ionIcon} icon={save}></IonIcon>
                        </IonButton>
                    </IonCol>
                </IonRow>
                </form>
                </IonGrid>
            </fieldset><br></br>
            {dataHead.idHeaderBagControl && (
                detailView()
            )}
        </div>
    );
};

export default JabaForm;