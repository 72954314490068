import React, { useState, useEffect } from 'react';
import styles from "../Css/ModalComponent.module.scss";
import { IonModal, IonHeader, IonToolbar,  IonTitle, IonButtons, IonButton,
IonContent, IonCard, IonCardSubtitle,  IonList, IonRow, IonCol, IonLoading,
useIonToast, IonIcon} from '@ionic/react';
import { getInformationRestaurant } from '../Services';
import { closeCircle, mapSharp } from 'ionicons/icons';

const ModalInfoRestaurant = ({open, fn, idStore}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [present] = useIonToast();
    const [content, setContent] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        setIsOpen(open);
        if(open){
            const restaurant = JSON.parse(sessionStorage.getItem('restaurant'+idStore));
			      if(restaurant){
                const headersR = JSON.parse(sessionStorage.getItem('headersRestaurant'));
                setContent([restaurant]);
                setHeaders(headersR);
			      } else {
                setLoading(true);
                getInformationRestaurant(idStore).then((response) => {
                    setLoading(false);
                    if (response.status) {
                        setContent(response.data);
                        setHeaders(response.headers);
                    } else {
                        response.messages.map((message) => (
                            present({
                            message: message,
                            duration: 3000,
                            position: 'bottom',
                            color: 'danger',
                            icon: closeCircle
                            })
                        ));
                    }
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const printBody = () => {
        const rowBody = content.map((item, key) => (
          <IonRow key={key}>
            {headers.map(header => {
              if (header.value === 'linkgooglemaps' && item[header.value]) {
                return (
                  <IonCol size='12' key={header.value}>
                    <IonButton expand="block" href={item[header.value]} target='_blank'>UBICACIÓN 
                        <IonIcon className={styles.iconSave} icon={mapSharp}></IonIcon>
                    </IonButton>
                  </IonCol>
                );
              } else if (header.value !== 'linkgooglemaps') {
                return (
                  <IonCol size='6' key={header.value}>
                    <h2><b>{header.text}</b></h2> {item[header.value]}
                  </IonCol>
                );
              } else {
                return ('');
              }
            })}
          </IonRow>
        ));
    
        return rowBody;
      }

    return (
        <IonModal isOpen={isOpen}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{idStore}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => fn('')}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className={styles.center}> 
		            <IonCard>
                        <IonCardSubtitle>Informacion del Restaurante {idStore} </IonCardSubtitle>
                        <IonList>
                            {printBody()}
                        </IonList>
                    </IonCard>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ModalInfoRestaurant;
