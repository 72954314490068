import React, { useEffect, useState } from "react";
import { IonContent, IonCard, IonCardSubtitle, IonCol, IonSearchbar,
    IonCardHeader, IonCardTitle, IonIcon, IonRow, IonCardContent,
	IonAlert, IonLoading, useIonToast } from "@ionic/react";
import styles from "./Css/Home.module.scss";
import CustomHeader from '../../General/Components/CustomHeader';
import { helpContent } from './Data/Data';
import { useHistory } from "react-router-dom";
import { getMenu, getRestaurant, updateToken } from './Services';
import { closeCircle, bus, person, reader} from 'ionicons/icons';

function Home() {
    const username = sessionStorage.getItem('username');
    const [ fields, setFields ] =  useState([]);
	const [ results, setResults ] = useState([]);
	const [ showRestaurants, setShowRestaurants] = useState(false);
	const [ restaurants, setRestaurants ] =  useState([]);
	const [ url, setUrl] = useState('');
	const history = useHistory();
	const [present] = useIonToast();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
        initialData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialData = () => {
		const homeList = JSON.parse(sessionStorage.getItem('homeList'));
		if(homeList){
			const restaurantList = JSON.parse(sessionStorage.getItem('restaurantList'));
			setFields(homeList);
			setResults(homeList);
			setRestaurants(restaurantList);
		}else {
			setLoading(true);
			getMenu().then((response) => {
				if(response.status){
					setFields(response.data);
					setResults(response.data);
					sessionStorage.setItem('homeList', JSON.stringify(response.data));
					getRestaurant().then((response) => {
						if(response.status){
							setRestaurants(response.data);
							sessionStorage.setItem('restaurantList', JSON.stringify(response.data));
						}
						setLoading(false);
					});
				}else {
					setLoading(false);
					response.messages.map((message)=> (
                        present({
                            message: message,
                            duration: 3000,
                            position: 'bottom',
                            color: 'danger',
                            icon: closeCircle
                        })
					));
				}
			});
		}
    };

    const search = (e) => {
		const searchTerm = e.currentTarget.value;
		if (searchTerm !== "") {
			const searchTermLower = searchTerm.toLowerCase();
			const newResults = fields.filter(e => e.name.toLowerCase().includes(searchTermLower));
			setResults(newResults);
		} else {
			setResults(fields);
		}
	}

	const restaurantSelected = (value) => {
		value = value.split('_'); 
		const idRestaurant = sessionStorage.getItem('idRestaurant');
		if(idRestaurant && idRestaurant === value[0]){
			history.push(url);
		} else {
			sessionStorage.setItem('idRestaurant', value[0]);
			sessionStorage.setItem('place', value[1]);
			setLoading(true);
			const data = {
				id_restaurant: value[0],
				place: value[1]
			}
			updateToken(data).then((response) => {
				if(response.status){
					history.push(url);
				}else {
					response.messages.map((message)=> (
                        present({
                            message: message,
                            duration: 3000,
                            position: 'bottom',
                            color: 'danger',
                            icon: closeCircle
                        })
					));
					setLoading(false);
				}
			});
		}
	}

	const setToModule = (url) => {
		if(restaurants && restaurants.length > 1){
			setUrl(url);
			setShowRestaurants(true);
		} else if(restaurants && restaurants.length === 1) {
			const idRestaurant = sessionStorage.getItem('idRestaurant');
			if(idRestaurant){
				history.push(url);
			} else {
				const value = restaurants[0].value.split('_'); 
				sessionStorage.setItem('idRestaurant', value[0]);
				sessionStorage.setItem('place', value[1]);
				setLoading(true);
				const data = {
					id_restaurant: value[0],
					place: value[1]
				}
				updateToken(data).then((response) => {
					if(response.status){
						history.push(url);
					}else {
						response.messages.map((message)=> (
							present({
								message: message,
								duration: 3000,
								position: 'bottom',
								color: 'danger',
								icon: closeCircle
							})
						));
						setLoading(false);
					}
				});
			}
		} else {
			present({
				message: 'El usuario no tiene restaurantes asociados',
				duration: 3000,
				position: 'bottom',
				color: 'danger',
				icon: closeCircle
			});	
		}
	}

	const getIconUrl = (iconName) => {
		switch (iconName) {
			case "bus":
				return bus;
			case "person":
				return person;
			case "reader":
				return reader;
			default:
				return "";
		}
	};

    return (
    <>
		<IonLoading isOpen={loading} message="Cargando..." />
        <CustomHeader name={'Menú'} content={helpContent()} closeSession='1'/>
        <IonContent scrollY={false}>
		<div className={styles.center}> 
		<IonCard className={styles.ionCard}>	
			<IonCardSubtitle className={ styles.results }>Bienvenid@ {username}</IonCardSubtitle>
			<IonSearchbar onIonInput={ e => search(e) } placeholder="Buscar Menú..." icon={ search }/>
			<fieldset>
				<legend>Menú</legend>
				<div className={ styles.Row } >
				<IonRow>
					{ results.map((result) => {
						return (
							<IonCol size="6" key={ result.screenCode }>
								<IonCard  onClick={()=>{(result.showModalRestaurant === 1)? setToModule(result.url) : history.push(result.url)}}>
									<IonCardHeader style={{ "padding": '10px' }}>
										<IonCardTitle>
											<IonIcon icon={getIconUrl(result.icon.trim())} style={{ color: 'rgba(180, 23, 73, 1)'}}></IonIcon>
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent><b>{result.displayName}</b></IonCardContent>
								</IonCard>
							</IonCol>
						);
					})}
				</IonRow>
				</div>
			</fieldset>
        </IonCard>
		
		</div>
    	</IonContent>
		<IonAlert
			isOpen={showRestaurants}
			onDidDismiss={() => setShowRestaurants(false)}
			cssClass="my-custom-class"
			header={"Seleccione el restaurante"}
			inputs={restaurants}
			buttons={[
				{
					text: "Cancelar",
					role: "cancel",
					handler: () => {
						setUrl('');
					}
				},
				{
					text: "Aceptar",
					handler: (data) => {
						restaurantSelected(data);
					}
				}
			]}
		/>
    </>
    );
}

export default Home;