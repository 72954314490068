import React, { useState, useEffect } from 'react';
import styles from "../Css/ModalComponent.module.scss";
import { IonModal, IonHeader, IonToolbar,  IonTitle, IonButtons, IonButton,
IonContent, IonCard, useIonToast, IonLoading, IonRow,
IonCol, IonGrid} from '@ionic/react';
import { detailRemisionGuides } from '../Services';
import { closeCircle } from 'ionicons/icons';

const ModalDetailRoadMap = ({open, fn, content}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [present] = useIonToast();
    const [details, setDetails] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        setIsOpen(open);
        if(open){
            setLoading(true);
            detailRemisionGuides(content.idGuide).then((response) => {
                setLoading(false);
                if (response.status) {
                    setDetails(response.data);
                    setHeaders(response.headers);
                } else {
                    response.messages.map((message) => (
                        present({
                        message: message,
                        duration: 3000,
                        position: 'bottom',
                        color: 'danger',
                        icon: closeCircle
                        })
                    ));
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const printBody = () => {
        const rowBody = details.map((item, key) => (
          <IonRow key={key} className={styles.col}>
             <IonCol size='9'>
            {headers.map((header, keyD) => {
                return (
                    <label key={keyD}>
                      <b>{header.text}</b> {item[header.value]} <br></br>
                    </label>
                );
            })}
            </IonCol>
            <IonCol size='3' class="ion-text-center">
                {item.quantity}
                </IonCol>
          </IonRow>
        ));
    
        return rowBody;
    }

    return (
        <IonModal isOpen={isOpen}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Detalle Guía # {content.idGuideErp}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => fn()}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className={styles.center}>           
		            <IonCard class='card-center'>
                        <IonGrid>
                            <IonRow className={styles.header}>
                                <IonCol size='9' class="ion-text-center">Detalle</IonCol>
                                <IonCol size='3' class="ion-text-center">Cantidad</IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonGrid>
                            {printBody()}
                        </IonGrid>
                    </IonCard>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ModalDetailRoadMap;
