import React, { useState, useEffect } from 'react';
import styles from "../Css/ModalComponent.module.scss";
import { IonModal, IonHeader, IonToolbar,  IonTitle, IonButtons, IonButton,
IonContent, IonCard, IonCardSubtitle,  IonList, IonItem, IonIcon} from '@ionic/react';

const ModalComponent = ({open, fn, content}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    return (
        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>AYUDA DE PANTALLA</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => fn()}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className={styles.center}> 
		            <IonCard>
                        <IonCardSubtitle>  FUNCIONALIDADES DE PANTALLA </IonCardSubtitle>
                        <div className={styles.textFunction}>
                            {content.descriptionScreen}
                        </div>
                        <IonCardSubtitle>  BOTONES </IonCardSubtitle>
                        <IonList>
                        {content.butonGuide.map((result, index) => {
                            return (
                                <IonItem key={index}>
                                    <div className={styles.itemText}>
                                        <IonIcon icon={result.icon} size='medium'></IonIcon>
                                        <b>{result.descriptionButon}</b>
                                        {result.description}
                                    </div>
                                </IonItem>
                            );
                        })}
                        </IonList>
                        <IonCardSubtitle><b>GUÍA DE USO</b></IonCardSubtitle>
                        <IonList>
                        {content.userGuide.map((result, index) => {
                            return (
                                <IonItem key={index}>
                                    <div className={styles.itemText}>
                                        <b>{result.tittle}</b>: &nbsp;
                                        {result.description}
                                    </div>
                                </IonItem>
                            );
                        })}
                        </IonList>
                    </IonCard>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ModalComponent;
