import { getPhysicTakeHeaders } from '../Services';
import { IonRow, IonCol, IonGrid, IonButton, IonLoading, IonIcon, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import styles from "../Css/PhysicalTakeHeader.module.scss";
import { eyeOutline, closeCircle } from 'ionicons/icons';

const PhysicalTakeHeader = ({ charge, fn }) => {
  const [headers, setHeader] = useState([]);
  const [data, setBody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tittle] = useState('Detalle Toma Física');
  const [initialCharge] = useState(true);
  const [present] = useIonToast();

  useEffect(() => {
    setLoading(true);
    if (!charge) {
      const headers = JSON.parse(sessionStorage.getItem('physicalTakeHeader'));
      const data = JSON.parse(sessionStorage.getItem('PhysicalTakeList'));
      setHeader(headers);
      setBody(data);
      setLoading(false);
    } else {
      const data = { status: 2 };
      getPhysicTakeHeaders(data).then((response) => {
        setLoading(false);
        if (response.status) {
          if (response.data.length > 0 && response.headers.length > 0) {
            setHeader(response.headers);
            setBody(response.data);
            sessionStorage.setItem('physicalTakeHeader', JSON.stringify(response.headers));
            sessionStorage.setItem('PhysicalTakeList', JSON.stringify(response.data));
          }
        } else {
          response.messages.map((message) => (
            present({
              message: message,
              duration: 3000,
              position: 'bottom',
              color: 'danger',
              icon: closeCircle
            })
          ));
        }
      });
    }
  }, [initialCharge, charge, present]);

  const printHeader = () => {
    const colHeader = headers.map((header) => (
      <IonCol key={header.value} className={styles.header}>
        <center>{header.text}</center>
      </IonCol>
    ));
    return colHeader;
  };

  const printBody = () => {
    const rowBody = data.map((item, key) => (
      <IonRow key={key} className={styles.items} fixed={true}>
        {headers.map(header => {
          if (header.value === 'actions') {
            return (
              <IonCol key={header.value} className={styles.col}>
                <center>
                  <IonButton onClick={() => { fn('detail', tittle, item.physicTakeHeaderCode) }}>
                    <IonIcon icon={eyeOutline}></IonIcon>
                  </IonButton>
                </center>
              </IonCol>
            );
          } else {
            return (
              <IonCol key={header.value} className={styles.col}>
                <center>{item[header.value]}</center>
              </IonCol>
            );
          }

        })}
      </IonRow>
    ));
    return rowBody;
  }

  return (
    <div >
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonGrid>
        <IonRow fixed={true}>
          {printHeader()}
        </IonRow>
        {printBody()}
      </IonGrid>
    </div>
  );
};

export default PhysicalTakeHeader;