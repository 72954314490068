import React, { useState } from "react";
import { IonList, IonCard, IonRow, IonCol, IonLoading, IonButton,
IonIcon, useIonToast, IonItem, IonInput} from '@ionic/react';
import { search, closeCircle, documentTextOutline } from 'ionicons/icons';
import { getRemissionGuides } from '../Services';
import styles from "../Css/List.module.scss";

const List = ({save, fn}) => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [licensePlate, setLicensePlate] = useState('');
    const [freight, setFreight] = useState('');
    const [present] = useIonToast();

    const searchHeader = () => {
        let licensePlate = document.getElementById('licensePlate').value;
        licensePlate = licensePlate.toUpperCase();
        let freight = document.getElementById('freight').value;
        if (licensePlate && freight){
            setLoading(true);
            setFreight(freight);
            setLicensePlate(licensePlate);
            getRemissionGuides(freight, licensePlate).then((response) => {
                setLoading(false);
                if (response.status) {
                    setDetails(response.data);
                    setHeaders(response.headers);
                } else {
                    redirectDetailRoadMap(freight, licensePlate);
                }
            });
        } else {
            present({
				message: 'Debe ingresar la placa y el código del flete',
				duration: 3000,
				position: 'bottom',
				color: 'secondary',
				icon: closeCircle
			});	
        }
    }

    // Función para actualizar el valor de la placa en mayúsculas
    const handleLicensePlateInput = (event) => {
        const inputValue = event.target.value.toUpperCase();
        setLicensePlate(inputValue);
    };

    function redirectDetailRoadMap(freight, licensePlate){
        fn(freight, licensePlate);
    }

    const printBody = () => {
        if(details.length > 0) {
            return (
                <div>
                    {details.map((item, key) => (
                    <IonCard key={ key }>
                        <IonRow>
                            <IonCol size="10">
                            {headers.map((header, keyD) => (
                                <label key={keyD}><b>{header.text}:</b> {item[header.value]} </label>
                            ))}
                            </IonCol>
                        </IonRow>
                    </IonCard>
                    ))}
                    <IonButton expand="block"  onClick={() => save(details, freight, licensePlate)}>  Agregar a Hoja de Ruta
                        <IonIcon icon={documentTextOutline}></IonIcon>
                    </IonButton>
                </div>
            );
        }
        
    }

    return (
        <div >
            <IonLoading isOpen={loading} message="Cargando..." />
            <div className={ styles.resultHeader }>
                <fieldset>
                    <legend>Guías de Remisión</legend>
                    <div className={ styles.content }>
                        <IonRow>
                            <IonCol size="5">
                                <IonItem fill="outline">
                                    <IonInput name="licensePlate" id="licensePlate" type="text"  placeholder="Placa" onInput={handleLicensePlateInput} value={licensePlate} required></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="4">
                                <IonItem fill="outline">
                                    <IonInput name="freight" id="freight" type="text" required placeholder="Flete"></IonInput>
                                </IonItem>
                            </IonCol>
                            <IonCol size="3" align='center'>
                                <IonButton  onClick={searchHeader}>
                                    <IonIcon icon={search}></IonIcon>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <ion-item-divider>
                            <ion-label> Resultados de búsqueda </ion-label>
                        </ion-item-divider>
                        <IonList>
                            {printBody()}
                        </IonList>
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

export default List;