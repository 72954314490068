export const formatHeaderBagControlBack = () => {
    return [
        { rename : 'id', name: 'idHeaderBagControl'},
        { rename : 'material_name', name: 'nameArticle'},
        { rename : 'type_header', name: 'typeHeader'},
        { rename : 'security_code', name: 'securityCode'},
        { rename : 'date', name: 'date'},
        { rename : 'local', name: 'storeCode'},
        { rename : 'temperature', name: 'temperature'},
        { rename : 'freight_number', name: 'freightNumber'},
        { rename : 'id_header_roadmap', name: 'idHeaderRoadMap'},
        { rename : 'id_article', name: 'idArticle'},
        { rename : 'status', name: 'status'},
        { rename : 'headlock', name: 'truckLock'},
    ];
}

export const formatDetailBagControlBack = () => {
    return [
        { rename : 'id', name: 'idDetaiilBagControl'},
        { rename : 'id_header', name: 'idHeaderBagControl'},
        { rename : 'type_bag', name: 'typeBag'},
        { rename : 'quantity_delivered', name: 'deliveredQuantity'},
        { rename : 'quantity_received', name: 'receivedQuantity'}
    ];
}

export const formatReturnHeaderBack = () => {
    return [
        { rename : 'id_roadmap', name: 'idDetailRoadMap'},
        { rename : 'purchase_code', name: 'purchaseCode'},
        { rename : 'provider_code', name: 'providerId'},
        { rename : 'creation_date', name: 'date'},
        { rename : 'status', name: 'status'},
        { rename : 'comment', name: 'comment'},
        { rename : 'cod_mot_dev', name: 'returnCode'}
    ];
}

export const formatReturnDetailBack = () => {
    return [
        { rename : 'article_code', name: 'codeSIR'},
        { rename : 'value', name: 'cost'},
        { rename : 'name', name: 'itemName'},
        { rename : 'unit_code', name: 'itemUnit'},
        { rename : 'quantity', name: 'quantity'},
        { rename : 'subrecet_code', name: 'subrecetCode'},
        { rename : 'tax', name: 'tax'},
    ];
}