import HttpClient from '../../services/HttpCilent';
import { returnResponse, formatData } from '../../Helper/Helpers';
import { headersGuidesRemission, formatGuidesRemission, headersRoadMap, 
formatHeaderRoadMap, formatRoadMap, formatInfoRoadMap, formatBagsArticle,
formatHeaderBagControl, formatDetailBagControl, headersDetailRemisionGuide,
formatDetailRemisionGuide, formatSearchRefund, formatRefund, formatDetailRefund } from './Data/Formats';
import { formatInfoRoadMapBack, formatHeaderBagControlBack, 
formatDetailBagControlBack } from './Data/FormatsBack';


export const getRemissionGuides = (freightNumber, licensePlateNumber) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/headerRemisionGuides/getByLicenseAndFreightJoinRestaurantWithRoadMapNull?'
            +'freight_number='+freightNumber
            +'&license_plate_number='+licensePlateNumber
        ).then(response => {
            resolve(returnResponse(response, formatGuidesRemission(), headersGuidesRemission()));
        })
    })
}

export const getHeaderRoadMap = (freight, licensePlate) => {
    return new Promise((resolve) => {
        HttpClient.get('/api/roadMapHeaders/getByLicenseAndFreight?'
        +'freight_number='+freight
        +'&license_plate_number='+licensePlate
        ).then(response => {
            resolve(returnResponse(response, formatHeaderRoadMap()));
        })
    })
}

export const createDetailRoadMap = (id, data) => {
    let detail = formatDetailRoadMap(id, data);
    return new Promise((resolve) => {
        HttpClient.post('/api/carrierRoadMaps?type=createRoadMapAndUpdateHeaderRemision'
            ,detail
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const getRoadMaps = (freight, licensePlate) => {
    return new Promise((resolve) => {
        HttpClient.get('/api/roadMapHeaders/getByLicenseAndFreightJoinDetailRoadMapAndHeaderGuide?'
        +'freight_number='+freight
        +'&license_plate_number='+licensePlate
        ).then(resp => { 
            let response;
            if (resp.data.response && resp.data.data !== null) {
                let data = formatCarrierRoadMap(resp.data.data, formatRoadMap());
                response = {
                    status: resp.data.response,
                    data: data.data,
                    idStores: data.stores,
                    headers: headersRoadMap()
                };
            } else {
                response = {
                    status: resp.data.response,
                    data: [],
                    idStores: [],
                    headers: [],
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        })
    })
}

export const updateRoadMapheader = (data) => {
    data.plantArrivalTime = (new Date(data.plantArrivalTime)).toLocaleTimeString('en-GB');
    data.chargingStartTime = (new Date(data.chargingStartTime)).toLocaleTimeString('en-GB');
    data.finalLoadingTime = (new Date(data.finalLoadingTime)).toLocaleTimeString('en-GB');
    data.plantDepartureTime = (new Date(data.plantDepartureTime)).toLocaleTimeString('en-GB');
    let detail = formatData(data, formatInfoRoadMapBack());
    return new Promise((resolve) => {
        HttpClient.put('/api/roadMapHeaders/'+detail.id+'?type=basic'
            ,detail
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const getInfoHeaderRoadMap = (idHeaderRoadMap) => {
    return new Promise((resolve) => {
        HttpClient.get('/api/roadMapHeaders/getById?id='+idHeaderRoadMap
        ).then(response => {
            resolve(returnResponse(response, formatInfoRoadMap()));
        })
    })
}

export const getBagsArticles = () => {
    return new Promise((resolve) => {
        HttpClient.get('/api/bagsArticles/getAll'
        ).then(response => {
            resolve(returnResponse(response, formatBagsArticle()));
        })
    })
}

export const detailRemisionGuides = (idGuide) => {
    return new Promise((resolve) => {
        HttpClient.get('api/detailRemisionGuides/getDetailByHeaderRemissionGuideIdAndArticle?'
            +'headerRemissionGuideId='+idGuide
        ).then(response => {
            resolve(returnResponse(response, formatDetailRemisionGuide(), headersDetailRemisionGuide()));
        })
    })
}

export const updateRemisionGuide = (data) => {
    return new Promise((resolve) => {
        HttpClient.put('/api/headerRemisionGuides/'+data.id+'?'
            +'type=basic'
            +'&id='+data.id,
            data
        ).then(resp => {
            let response = {
                status: resp.data.response, 
                data: resp.data.data,
                headers: resp.data.headers,
                messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
            }
            resolve(response);
        })
    })
}

export const getInformationRestaurant = (storeCode) => {
    return new Promise((resolve) => {
        HttpClient.get('api/restaurants/getRestaurantByStoreCode?'
            +'storeCode='+storeCode
        ).then(resp => {
            let response;
            if (resp.data.response) {
                response = {
                    status: resp.data.response,
                    data: [resp.data.data],
                    headers: resp.data.headers
                };
                sessionStorage.setItem("restaurant"+storeCode, JSON.stringify(resp.data.data));
                sessionStorage.setItem("headersRestaurant", JSON.stringify(resp.data.headers));
            } else {
                response = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        })
    })
}


export const updateRoadMap = (data) => {
    return new Promise((resolve) => {
        HttpClient.put('/api/carrierRoadMaps/0?type=byArrayFromTransportist'
            , data
        ).then(resp => {
            let response = {
                status: resp.data.response,
                messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
            };
            resolve(response);
        })
    })
}

export const updateStatusRoadMap = (data) => {
    return new Promise((resolve) => {
        HttpClient.put('/api/carrierRoadMaps/0?type=updateStatusByArray'
            , data
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const createBagControl = (data) => {
    let bagControl = formatData(data, formatHeaderBagControlBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/bagControlHeaders?type=basic'
            ,bagControl
        ).then(response => {
            resolve(returnResponse(response, formatHeaderBagControl()));
        })
    })
}

export const updateBagControl = (data) => {
    let bagControl = formatData(data, formatHeaderBagControlBack());
    return new Promise((resolve) => {
        HttpClient.put('/api/bagControlHeaders/'+bagControl.id+'?type=basic'
            ,bagControl
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const createDetailBagControl = (data) => {
    let detailBagControl = formatData(data, formatDetailBagControlBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/bagControlDetails?type=basic'
            ,detailBagControl
        ).then(response => {
            resolve(returnResponse(response, formatDetailBagControl()));
        })
    })
}

export const deleteDetailBagControl = (id) => {
    return new Promise((resolve) => {
        HttpClient.delete('/api/bagControlDetails/'+id+'?type=logic&id='+id
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const updateDetailBagControl = (data) => {
    let detailBagControl = formatData(data, formatDetailBagControlBack())
    return new Promise((resolve) => {
        HttpClient.put('api/bagControlDetails/'+detailBagControl.id+'?type=basic'
            ,detailBagControl
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const getBagControl = (codeStore, idHeaderRoadMap, freightNumber, type) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/bagControlHeaders/getByStoreCodeDateFreightTypeAndHeaderRoadmap?'
            +'store_code='+codeStore
            +'&id_header_roadmap='+idHeaderRoadMap
            +'&freight_number='+freightNumber
            +'&type_header='+type
            +'&status=1'
        ).then(resp => {
            let response;
            if (resp.data.response) {
                response = {
                    status: resp.data.response, 
                    data: formatData(resp.data.data[0], formatHeaderBagControl())
                };
            } else {
                response = returnResponse(resp);
            }
            resolve(response);
        })
    })
}


export const getDetailBagControl = (idHeaderBagControl) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/bagControlDetails/getByIdHeader?id_header='+idHeaderBagControl
            +'&status=1'
        ).then(response => {
            resolve(returnResponse(response, formatDetailBagControl()));
        })
    })
}

export const getRefund = (idReturn, idRestaurant) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/returns/getReturnById?'
            +'id_return='+idReturn
            +'&id_restaurant='+idRestaurant
        ).then(response => {
            resolve(returnResponse(response, formatRefund()));
        })
    })
}

export const getDetailRefund = (idReturn, idRestaurant) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/returns/getReturnDetailsById?'
            +'id_return='+idReturn
            +'&id_restaurant='+idRestaurant
        ).then(response => {
            resolve(returnResponse(response, formatDetailRefund()));
        })
    })
}

export const getSearchRefund = () =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/carrierRoadMaps/getByUserId?status=4'
        ).then(resp => {
            let response;
            if (resp.data.response) {
                response = {
                    status: resp.data.response, 
                    data: formatData(resp.data.data[0], formatSearchRefund())
                };
            } else {
                response = returnResponse(resp);
            }
            resolve(response);
        })
    })
}

export const updateRoadMatReturns = (idRoadMapReturn, status) => {
    let data = {id: idRoadMapReturn, status:status};
    return new Promise((resolve) => {
        HttpClient.put('/api/roadMapReturns/'+idRoadMapReturn+'?type=basic'
            ,data
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const updateReturns = (idRoadMapReturn, status, idRestaurant) => {
    let data = {id: idRoadMapReturn, status:status, restaurant_id:idRestaurant};
    return new Promise((resolve) => {
        HttpClient.put('/api/returns/'+idRoadMapReturn+'?type=updateStatus'
            ,data
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const getRefundState = (idDetailRoadMap, status) =>{
    return new Promise((resolve) => {
        HttpClient.get('/api/carrierRoadMaps/finishDelivery/edit?'
            +'id='+idDetailRoadMap
            +'&status='+status
        ).then(response => {
            resolve(returnResponse(response)); 
        })
    })
}

function formatDetailRoadMap(id, data){
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let stores = {};
    let increment = 1;
    let send = [];
    data.forEach((element) => {
        if (stores[element.destinationStore] === undefined) {
            stores[element.destinationStore] = increment;
            increment++;
        }
        let deliveryDate = element.deliveryDate.split('.');
        send.push(
            {
                "id_header": id,
                "id_guide_erp": element.idGuideRemision,
                "id_restaurant": element.restaurantCode,
                "delivery_order": day + "" + month + "." + stores[element.destinationStore],
                "delivery_date": deliveryDate[0],
                "status": 1,
            }
        );
    });
    return send;
}


function formatCarrierRoadMap(response, format){
    let idStores = [];
    let data = {};
    response.forEach(function(element) {
        let item = {};
        format.map((header) => (
            item[header['rename']] = element[header['name']]
        ));
        if(!idStores.includes(item.destinationStore)){
            idStores.push(item.destinationStore);
        }
        if(!data[item.destinationStore]){
            data[item.destinationStore] = [];
        }
        data[item.destinationStore].push(item);
    });

    return {stores: idStores, data: data};
}