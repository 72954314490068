import { search, saveOutline, bus, newspaper, clipboard,
  storefront, chevronBackCircle, closeCircle, save, addCircle, reload} from 'ionicons/icons';

export const helpContentList = () => {
  return {
    descriptionScreen:"La pantalla de Guia de Remision Transportista es una interfaz de usuario diseñada para que los transportistas tengan una vista clara y organizada de las guías de remisión que tienen asignadas. La pantalla muestra una lista que contiene información relevante para cada guía, como el nombre del restaurante, el número de guía, la fecha y una breve descripción de la guía.",
    butonGuide:[
      {
        icon: search,
        descriptionButon: " Buscar Guía de Remisión",
        description: " Para buscar una guía de remisión se debe ingresar los campos placa y flete, una vez ingresados ambos campos se oprime el botón de buscar.  En caso de que la búsqueda genere resultados se nos mostrara una lista con las guías de remisión asignadas al número de placa y al flete, en caso de no traer resultados se cargara una pantalla con las hojas de rutas del día actual."
      },
      {
        icon: saveOutline,
        descriptionButon: " Asociar Hoja de Ruta",
        description: " En la parte inferior de la lista, encontramos un botón 'Generar Hoja de Ruta', que permite a los transportistas aceptar todas las guías y redirigirlos a otra pantalla con información más detallada."
      }
    ],
    userGuide:[
      {
        tittle: "Guías de Remisión Transportista",
        description: "Al momento de ingresar la pantalla el usuario debe ingresar la placa y el flete para visualizar las guías de remisión asociadas a este, en caso de que se muestren guías de remisión que no están asociadas a una hoja de ruta se nos listaran para poder agregarlas a la hoja de ruta oprimiendo clic en el botón 'asociar hoja de ruta'. En caso de que no tengamos guías de remisión que deban ser asociadas a una hoja de ruta, la aplicación cambiara a la pantalla de hojas de rutas."
      }
    ]
  };
}

export const helpContentRoadMap = () => {
  return {
    descriptionScreen:"La pantalla hoja de rutas se utiliza para mostrar todas las guías de remisión que debe entregar el transportista.",
    butonGuide:[
      {
        icon: bus,
        descriptionButon: " Modificar Orden Hoja de Ruta",
        description: " Al oprimir clic en el botón se actualizará el orden en que se visualizan los registros en la hoja de ruta, de este orden depende como se cargue la información al momento de ingresar."
      },
      {
        icon: newspaper,
        descriptionButon: " Registro de Jabas",
        description: " Al oprimir clic en el botón se mostrará una nueva pantalla con el formulario para ingresar la información de las Jabas."
      },
      {
        icon: storefront,
        descriptionButon: " Información Tienda",
        description: " Al oprimir clic en el botón se mostrará una nueva pantalla con la información de la tienda a la cual se deben entregar los pedidos."
      },
      {
        icon: chevronBackCircle,
        descriptionButon: " Atras",
        description: " Al oprimir clic en el botón atrás se retornará a la pantalla principal del módulo de guías de remisión transportista."
      },
      {
        icon: reload,
        descriptionButon: " Recargar",
        description: " Al oprimir clic en el botón se actualizará la pantalla con los últimos registros que se hallan realizando sobre las guías de remisión."
      },
      {
        icon: clipboard,
        descriptionButon: " Cabecera hoja de ruta",
        description: " Al oprimir clic en el botón se desplegará una nueva pantalla en la cual se debe digitar la información de la cabecera de la hoja de ruta."
      },
    ],
    userGuide:[
      {
        tittle: "Iniciar Entrega",
        description: "Para iniciar la entrega en un restaurante primero se debe diligenciar el formulario de las jabas. Una vez diligenciado dicho formulario se oprime clic en el botón con el nombre del restaurante para iniciar la entrega de las guías de remisión."
      },
      {
        tittle: "Modificar Orden Hoja de Ruta",
        description: "Para modificar el orden de las guías de remisión se debe dar clic sostenido en el botón que tiene el código del restaurante seguido del número de entrega, se moverá a la posición que se desea y una vez ordenadas se debe oprimir clic en el botón 'Modificar Orden Hoja de Ruta' el cual actualizará el orden de la hoja de ruta para la próxima vez que ingresemos a la pantalla."
      },
      {
        tittle: "Ver Detalle la Orden",
        description: " Para ver el detalle de una orden se debe dar clic en el recuadro rojo de la orden de pedido el cual nos desplegara una nueva ventana informativa con el detalle de los productos a entregar, se debe tener en cuenta que este recuadro solamente se activara si la guía de remisión se encuentra en estado entregando."
      }
    ]
  };
}

export const helpContentJabaForm = () => {
  return {
    descriptionScreen:"La pantalla 'Formulario de Jabas' permite a los usuarios ingresar y gestionar información sobre las cabeceras y detalles de las Jabas. Los usuarios pueden crear nuevas cabeceras de Jabas, agregar detalles a estas cabeceras y gestionar los detalles ingresados.",
    butonGuide:[
      {
        icon: closeCircle,
        descriptionButon: " Eliminar detalles de Jabas",
        description: " Ubicado en la columna de Operaciones del listado de detalles de Jabas. Este botón abrirá una ventana emergente el cual nos indicara si realmente queremos eliminar el detalle de Jabas si confirmamos el modas se realizara el proceso de eliminación del detalle."
      },
      {
        icon: save,
        descriptionButon: " Agregar o Editar Cabecera de Jaba",
        description: " Este botón se encarga de crear la cabecera de las Jabas en el caso de que no existan datos. Por el contrario si existen datos relacionados a esa Jabas se editara la información."
      },
      {
        icon: addCircle,
        descriptionButon: " Agregar detalle de Jabas",
        description: " Este botón se encarga de realizar la función de insertar un detalle de Jaba. Cabe a destacar que todos los campos son requeridos para realizar esta opción."
      }
    ],
    userGuide:[
      {
        tittle: "Formulario de Cabecera de Jabas",
        description: "En la sección inicial, los usuarios encuentran un formulario para ingresar información de la cabecera de las Jabas. Este formulario cuenta con los campos: Artículo, Temperatura, Código . Cabe a destacar que si al iniciar el formulario existen datos de la cabecera de Jabas se cargaran los datos por defecto y la acción del botón será editar en vez de crear"
      },
      {
        tittle: "Formulario de Detalles de Jabas",
        description: "Después de crear una cabecera de Jaba, se habilita la sección de Detalles de Jabas. Esta sección es otro formulario que cuenta con los campos Tipo, Cantidad Entregada y Cantidad Recibida"
      },
      {
        tittle: "Listado de Detalles de Jabas",
        description: "Ubicado en la parte inferior de la pantalla el listado de detalles muestra cada uno de los detalles creados a través de tabla con las columnas: Detalle, Cantidad Entregada, Cantidad Recibida y Operación."
      }
    ]
  };
}

export const helpRefund = () => {
  return {
    descriptionScreen:"La pantalla 'Devoluciones' se utiliza para mostrar una devolución que se haya generado al momento de entregar las guías de remisión. En esta pantalla se mostrará la información de por qué se realizó la devolución, una lista del detalle de los artículos devueltos y la opción de aceptar o rechazar la misma. ",
    butonGuide:[
      {
        icon: closeCircle,
        descriptionButon: " Rechazar Devolución",
        description: " El botón se encuentra al final de la pantalla y se utiliza para cambiar el estado de la devolución ha rechazado."
      },
      {
        icon: addCircle,
        descriptionButon: " Aceptar Devolución",
        description: " El botón se encuentra al final de la pantalla y se utiliza para cambiar el estado de la devolución ha aceptado."
      }
    ],
    userGuide:[
      {
        tittle: "Aceptar o rechazar devolución",
        description: "Para aceptar o rechazar una devolución, el usuario deberá ir al final de la pantalla y oprimir el botón correspondiente. "
      },
    ]
  };
}

export const helpHeaderRoadMap = () => {
  return {
    descriptionScreen:"La pantalla 'Cabecera Hoja de Ruta' permite a los usuarios ingresar la información de la cabecera para la generación del reporte de las hojas de ruta.",
    butonGuide:[
      {
        icon: save,
        descriptionButon: " Guardar",
        description: " Este botón se encarga de guardar la información de la cabecera de la hoja de ruta."
      },
      {
        icon: chevronBackCircle,
        descriptionButon: " Atras",
        description: " Al oprimir clic en el botón atrás se retornará a la pantalla hoja de ruta."
      }
    ],
    userGuide:[
      {
        tittle: " Formulario cabecera hoja de ruta",
        description: " Para realizar le registro de la cabecera de la hoja de ruta se deben diligenciar todos los campos del formulario y una vez que se está seguro de que los campos están diligenciados correctamente, se oprime en el botón guardar."
      },
    ]
  };
}