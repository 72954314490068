import React, { useEffect, useState } from 'react';
import styles from "../Css/HeaderRoadMap.module.scss";
import { IonButton, useIonToast, IonLoading, IonRow, IonCol, IonItem, IonModal,
IonGrid, IonAlert, IonIcon, IonInput, IonDatetime, IonDatetimeButton } from '@ionic/react';
import { save, closeCircle } from 'ionicons/icons';
import { updateRoadMapheader, getInfoHeaderRoadMap } from '../Services';

const HeaderRoadMap = ({idHeaderRoadMap, returnDetial}) => {
    const [ loading, setLoading] = useState(false);
    const [present] = useIonToast();
    const [ showAlert, setShowAlert] = useState(false);
    const [ data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getInfoHeaderRoadMap(idHeaderRoadMap).then((response) => {
            setLoading(false);
            if(response.data.idHeaderRoadMap){
                setData(response.data);
            } else {
                let date = new Date();
                data.idHeaderRoadMap = idHeaderRoadMap;
                data.plantArrivalTime = date.toLocaleTimeString('en-GB');
                data.chargingStartTime = date.toLocaleTimeString('en-GB');
                data.finalLoadingTime = date.toLocaleTimeString('en-GB');
                data.plantDepartureTime = date.toLocaleTimeString('en-GB');
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleChange(e){
        data[e.target.id] = e.detail.value;
    };

    function updateHeaderRoadMap(){
        setLoading(true);
        updateRoadMapheader(data).then((response) => {
            setLoading(false);
            if(response.status){
                returnDetial();
            }else{
                messageError(response.messages);
            }
        });
    }

    function validateData(){
        let save = true;
        let messages = [];
        if(!data.initialTemperature){
            messages.push('Debe ingresar el campo temperatura inicial del camión');
            save = false;
        }
        if(!data.finalTemperature){
            messages.push('Debe ingresar el campo temperatura final del camión');
            save = false;
        }
        if(!data.sideDoor){
            messages.push('Debe ingresar el campo puerta lateral');
            save = false;
        }
        if(!data.rearDoor){
            messages.push('Debe ingresar el campo puerta posterior');
            save = false;
        }
        if(!data.route){
            messages.push('Debe ingresar el campo ruta');
            save = false;
        }
        if(!data.dispatchedBy){
            messages.push('Debe ingresar el campo despachado por');
            save = false;
        }
        if(!data.providerResponsible){
            messages.push('Debe ingresar el campo proveedor responsable');
            save = false;
        }
        if(!save){
            messageError(messages);
        }
        setShowAlert(save);
    }

    function messageError(messages){
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    return (
        <div className={styles.content}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonGrid className={styles.items}>
                <IonRow>
                    <IonCol size='6'>
                        <h2><b>Hora Llegada Planta</b></h2>
                        <IonItem>
                            <IonDatetimeButton datetime="plantArrivalTime"></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="plantArrivalTime"
                                    presentation="time"
                                    showDefaultButtons="true"
                                    doneText='Confirmar'
                                    cancelText='Cancelar'
                                    value={data.plantArrivalTime || ""}
                                    onIonChange={handleChange}
                                ></IonDatetime>
                            </IonModal>
                        </IonItem>
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Hora Inicio Carga</b></h2>
                        <IonItem>
                            <IonDatetimeButton datetime="chargingStartTime"></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="chargingStartTime"
                                    presentation="time"
                                    showDefaultButtons="true"
                                    doneText='Confirmar'
                                    cancelText='Cancelar'
                                    value={data.chargingStartTime || ""}
                                    onIonChange={handleChange}
                                ></IonDatetime>
                            </IonModal>
                        </IonItem>
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Hora Final Carga</b></h2>
                        <IonItem>
                            <IonDatetimeButton datetime="finalLoadingTime"></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="finalLoadingTime"
                                    presentation="time"
                                    showDefaultButtons="true"
                                    doneText='Confirmar'
                                    cancelText='Cancelar'
                                    value={data.finalLoadingTime || ""}
                                    onIonChange={handleChange}
                                ></IonDatetime>
                            </IonModal>
                        </IonItem>
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Hora Salida Planta</b></h2>
                        <IonItem>
                            <IonDatetimeButton datetime="plantDepartureTime"></IonDatetimeButton>
                            <IonModal keepContentsMounted={true}>
                                <IonDatetime id="plantDepartureTime"
                                    presentation="time"
                                    showDefaultButtons="true"
                                    doneText='Confirmar'
                                    cancelText='Cancelar'
                                    value={data.plantDepartureTime || ""}
                                    onIonChange={handleChange}
                                ></IonDatetime>
                            </IonModal>
                        </IonItem>
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Temp. Inicial Camion</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="initialTemperature"
                        value={data.initialTemperature || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Temp. Final Camion</b></h2><br></br>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="finalTemperature"
                        value={data.finalTemperature || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Puerta Lateral</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="sideDoor"
                        value={data.sideDoor || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Puerta Posterior</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="rearDoor"
                        value={data.rearDoor || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Ruta</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="route"
                        value={data.route || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Despachado Por</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="dispatchedBy"
                        value={data.dispatchedBy || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6'>
                        <h2><b>Prov. Responsable</b></h2>
                        <IonInput
                        label="Outline input"
                        labelPlacement="floating"
                        fill="outline"
                        id="providerResponsible"
                        value={data.providerResponsible || ""}
                        onIonChange={handleChange}
                        />
                    </IonCol>
                    <IonCol size='6' align='center'>
                        <br></br>
                        <IonButton onClick={()=>validateData()}> 
                            Guardar
                            <IonIcon slot="end" icon={save}></IonIcon>
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header='¿Está seguro que desea registrar / actualizar la información?'
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Aceptar",
                        handler: () => {
                            updateHeaderRoadMap();
                        }
                    }
                ]}
            />
        </div>
    );
};

export default HeaderRoadMap;
