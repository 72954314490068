export const headersGuidesRemission = () => {
    return [
        { text : 'Id Guia', value: 'idGuideRemision'},
        { text : 'Restaurante', value: 'destinationStore'},
        { text : 'Fecha', value: 'deliveryDate'}
    ];
}

export const formatGuidesRemission = () => {
    return [
        { rename : 'idGuideRemision', name: 'id'},
        { rename : 'restaurantCode', name: 'Cod_Restaurante'},
        { rename : 'destinationStore', name: 'storeDestinyManager'},
        { rename : 'deliveryDate', name: 'deliveryDateErp'}
    ];
}

export const headersRoadMap = () => {
    return [
      { text : 'Id Guia', value: 'idGuide'},
      { text : 'Orden', value: 'orderCode'}
    ];
}

export const formatHeaderRoadMap = () => {
    return [
        { rename : 'idHeaderRoadMap', name: 'id'}
    ];
}

export const formatRoadMap = () => {
    return [
        { rename : 'idDetailRoadMap', name: 'id'},
        { rename : 'idGuide', name: 'idGuideErp'},
        { rename : 'status', name: 'status'},
        { rename : 'idHeaderRoadMap', name: 'id_header'},
        { rename : 'orderCode', name: 'orderCode'},
        { rename : 'destinationStore', name: 'storeDestinyManager'},
        { rename : 'idRestaurant', name: 'id_restaurant'}
    ];
}

export const formatInfoRoadMap = () => {
    return [
        { rename : 'idHeaderRoadMap', name: 'id'},
        { rename : 'plantArrivalTime', name: 'hourArrivePlant'},
        { rename : 'chargingStartTime', name: 'hourBeginCharge'},
        { rename : 'finalLoadingTime', name: 'hourEndCharge'},
        { rename : 'plantDepartureTime', name: 'hourLeavePlant'},
        { rename : 'initialTemperature', name: 'tempBeginTruck'},
        { rename : 'finalTemperature', name: 'tempEndTruck'},
        { rename : 'sideDoor', name: 'lateralDoor'},
        { rename : 'rearDoor', name: 'backDoor'},
        { rename : 'route', name: 'route'},
        { rename : 'dispatchedBy', name: 'deliveryBy'},
        { rename : 'providerResponsible', name: 'providerResponsible'}
    ];
}

export const formatHeaderBagControl = () => {
    return [
        { rename : 'idHeaderBagControl', name: 'id'},
        { rename : 'restaurantCode', name: 'local'},
        { rename : 'temperature', name: 'temperature'},
        { rename : 'securityCode', name: 'securityCode'},
        { rename : 'nameArticle', name: 'materialName'},
        { rename : 'freightNumber', name: 'freightNumber'},
        { rename : 'idArticle', name: 'idArticle'}
    ];
}

export const formatDetailBagControl = () => {
    return [
        { rename : 'idDetaiilBagControl', name: 'id'},
        { rename : 'idHeaderBagControl', name: 'idHeader'},
        { rename : 'typeBag', name: 'typeBag'},
        { rename : 'deliveredQuantity', name: 'quantityDelivered'},
        { rename : 'receivedQuantity', name: 'quantityReceived'}
    ];
}

export const formatBagsArticle = () => {
    return [
        { rename : 'id', name: 'id'},
        { rename : 'descripcion', name: 'nombre'}
    ];
}

export const headersDetailRemisionGuide = () => {
    return [
      { text : 'Código SIR', value: 'codeSIR'},
      { text : 'Código ERP', value: 'codeERP'},
      { text : 'Costo', value: 'cost'},
      { text : 'Articulo', value: 'itemName'},
      { text : 'Unidad', value: 'itemUnit'}
    ];
}

export const formatDetailRemisionGuide = () => {
    return [
        { rename : 'codeSIR', name: 'managerArticleId'},
        { rename : 'codeERP', name: 'articleErpId'},
        { rename : 'cost', name: 'cost'},
        { rename : 'itemName', name: 'name'},
        { rename : 'itemUnit', name: 'unitErpBaseMeasure'},
        { rename : 'quantity', name: 'quantityManager'}
    ];
}

export const formatSearchRefund = () => {
    return [
        { rename : 'idDetailRoadMap', name: 'id'},
        { rename : 'idReturn', name: 'id_return'},
        { rename : 'idRestaurant', name: 'idRestaurante'},
        { rename : 'idRoadMapReturn', name: 'id_roadMapReturn'}
    ];
}

export const formatRefund = () => {
    return [
        { rename : 'reasonReturn', name: 'Descripcion'},
        { rename : 'comment', name: 'comment'}
    ];
}

export const formatDetailRefund = () => {
    return [
        { rename : 'itemCode', name: 'Cod_Art_Bodega'},
        { rename : 'description', name: 'Nombre'},
        { rename : 'quantity', name: 'Cantidad'}
    ];
}
