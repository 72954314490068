import { eyeOutline, search,  list, arrowBackOutline} from 'ionicons/icons';

export const helpContent = () => {
  return { 
    descriptionScreen:"La siguiente pantalla se utiliza para la modificación de las cantidades físicas de las tomas física de inventarios.", 
    butonGuide:[
      {
        icon: eyeOutline,
        descriptionButon: "Toma Física - (Detalle): ",
        description: 'Al dar clic en el botón se cargan los detalles asociados a la toma física.'
      },
      {
        icon: search,
        descriptionButon: "Detalle Toma Física - (Buscar): ",
        description: 'Al dar clic en el botón se realizará una búsqueda por nombre o código de artículo en los detalles de la toma física.'
      },
      {
        icon: list,
        descriptionButon: "Detalle Toma Física - (Listar Todos): ",
        description: 'Al dar clic en el botón se muestran todos los detalles asociados a la toma física.'
      }, {
        icon: arrowBackOutline,
        descriptionButon: "Detalle Toma Física - (volver): ",
        description: 'Al dar clic en el botón se regresará a la pantalla en la cual se muestran todas las tomas físicas en estado pendiente.'
      }
    ], 
    userGuide:[
      {
        tittle: "Buscar Detalle",
        description: "Para buscar un detalle en la toma física se debe escribir el nombre o el código del artículo y automáticamente se irán filtrando los detalles en la pantalla."
      },{
        tittle: "Actualizar Cantidad Fisica",
        description: "Para actualizar una cantidad física se debe escribir un valor en el campo Cantidad Física y una vez se salga del campo automáticamente se actualizará la cantidad."
      }
    ]
  };  
}

export const PhysicalTakeHeader = () => {
  return [
      {
          "text": "Tipo",
          "value": "typeTake"
      },
      {
          "text": "Fecha",
          "value": "date"
      },
      {
          "text": "Detalles",
          "value": "actions"
      }
  ];
}

export const PhysicalTakeHeaderDetail = () => {
  return [
      {
          "text": "Area",
          "value": "areaName"
      },
      {
          "text": "Nombre",
          "value": "name"
      },
      {
          "text": "Código Artículo",
          "value": "articleCode"
      },
      {
        "text": "Unidad",
        "value": "unitInventory"
      },
      {
          "text": "Grupo",
          "value": "articleGroup"
      }
      ,
      {
          "text": "Cantidad Física",
          "value": "actions"
      }
      
  ];
}