export const formatRoadMap = () => {
    return [
        { rename : 'idHeaderRoadMap', name: 'id'},
        { rename : 'idDetailRoadMap', name: 'id_detail_roadmap'},
        { rename : 'idGuide', name: 'idGuideErp'},
        { rename : 'status', name: 'status'},
        { rename : 'orderCode', name: 'orderCode'},
        { rename : 'destinationStore', name: 'storeDestinyManager'},
        { rename : 'idRestaurant', name: 'id_restaurant'},
        { rename : 'freightNumber', name: 'freightNumber'}
    ];
}

export const headersRoadMap = () => {
    return [
      { text : 'Id Guia', value: 'idGuide'},
      { text : 'Orden', value: 'orderCode'}
    ];
}

export const headersDetailRemisionGuide = () => {
    return [
      { text : 'Código SIR', value: 'codeSIR'},
      { text : 'Articulo', value: 'itemName'},
      { text : 'Unidad', value: 'itemUnit'}
    ];
}

export const formatDetailRemisionGuide = () => {
    return [
        { rename : 'codeSIR', name: 'managerArticleId'},
        { rename : 'codeERP', name: 'articleErpId'},
        { rename : 'cost', name: 'cost'},
        { rename : 'itemName', name: 'name'},
        { rename : 'itemUnit', name: 'unitErpBaseMeasure'},
        { rename : 'quantity', name: 'quantityManager'}
    ];
}

export const formatHeaderBagControl = () => {
    return [
        { rename : 'idHeaderBagControl', name: 'id'},
        { rename : 'restaurantCode', name: 'local'},
        { rename : 'temperature', name: 'temperature'},
        { rename : 'securityCode', name: 'securityCode'},
        { rename : 'nameArticle', name: 'materialName'},
        { rename : 'freightNumber', name: 'freightNumber'},
        { rename : 'idArticle', name: 'idArticle'}
    ];
}

export const formatDetailBagControl = () => {
    return [
        { rename : 'idDetaiilBagControl', name: 'id'},
        { rename : 'idHeaderBagControl', name: 'idHeader'},
        { rename : 'typeBag', name: 'typeBag'},
        { rename : 'deliveredQuantity', name: 'quantityDelivered'},
        { rename : 'receivedQuantity', name: 'quantityReceived'}
    ];
}

export const formatGuideToPurchase = () => {
    return [
        { rename : 'idPurchase', name: 'id'},
        { rename : 'movementId', name: 'idMovementCode'},
        { rename : 'restaurantCode', name: 'restaurantCode'},
        { rename : 'warehouseCode', name: 'warehouseCode'},
        { rename : 'providerId', name: 'providerCode'},
        { rename : 'movementTypeCode', name: 'movementTypeCode'},
        { rename : 'date', name: 'creationDate'},
        { rename : 'orderCode', name: 'orderCode'}
    ];
}

export const formatReasonReturn = () => {
    return [
        { rename : 'returnCode', name: 'codMovDet'},
        { rename : 'description', name: 'description'}
    ];
}

