import React, { useState, useEffect } from "react";
import { IonList, IonReorder, IonReorderGroup,
IonRow, IonCol, IonLoading, IonButton, IonIcon, IonItem, useIonToast,
IonAccordion, IonAccordionGroup, IonAlert} from '@ionic/react';
import { bus, storefront, arrowForwardOutline, closeCircle, receiptOutline, 
caretDownCircle, checkmarkCircleOutline, clipboard, reload} from 'ionicons/icons';
import styles from "../Css/List.module.scss";
import ModalInfoRestaurant from './ModalInfoRestaurant';
import ModalDetailRoadMap from './ModalDetailRoadMap';
import {updateRoadMap, updateStatusRoadMap, getSearchRefund, getBagControl} from '../Services';

const ListRoadMap = ({idStores, details, headers, idHeaderRoadMap, freightNumber, 
viewJabaForm, viewRefund, viewHeaderRoadMap}) => {
    const [loading, setLoading] = useState(false);
    const [data, setBody] = useState(idStores);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [idStore, setIdStore] = useState('');
    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [detail, setDetail] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [item, setItem] = useState('');
    const [present] = useIonToast();
    let storeCodeActive = '';
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAction, setTypeAction] = useState('');
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (count <= 6) {
            const interval = setInterval(() => {
                getSearchRefund().then((response) => {
                    if(response.status){
                        viewRefund(response.data);
                    }
                });
                setCount(count + 1);
            }, 15000);
            return () => clearInterval(interval);
        }
    });

    useEffect(() => {
        setBody(data);
        const accordionGroup = document.querySelector('ion-accordion-group');
        accordionGroup.value = [storeCodeActive];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    function handleReorder(event) {
        setBody(event.detail.complete(data));
    }

    function openInfo(idStore) {
        setIdStore(idStore);
        setIsOpenInfo(!isOpenInfo);
    }

    function execute(){
        if(typeAction === 'beginDelivery'){
            startDelivery();
        }else if(typeAction === 'updateOrder'){
            updateOrderRoadMap();
        }
    }

    const openAlert = (type, item) => {
        if(type === 'beginDelivery'){
            beginDelivery(item);
        } else if (type === 'updateOrder'){
            setMessageAlert( "¿Está seguro que desea actualizar el orden de la hoja de rutas?");
            setShowAlert(true);
        }
        setTypeAction(type);
    }

    function startDelivery(){
        let store = [];
        details[item].forEach(function(detail){
            let content = {};
            content['id'] = detail.idDetailRoadMap;
            store.push(content);
        });

        setLoading(true);
        updateStatusRoadMap(store).then((response) => {
            setLoading(false);
            if(response.status){
                updateStatus();
            } else {
                message(response.messages, false);
            } 
        });
    }

    function updateStatus(){
        let body = data.map(e => e);
        for (const detail in details) {
            let status = (detail === item)? '2' : '1';
            details[detail].map(detail => (
                detail.status = status
            ));
        }
        storeCodeActive = item;
        setBody(body);
    }

    function updateOrderRoadMap(){
        console.log(data);
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let store = [];
        let increment = 1;
        data.forEach(function(elemento) {
            details[elemento].forEach(function(detail){
                let content = {};
                content['delivery_order'] = day + "" + month + "." + increment;
                content['id'] = detail.idDetailRoadMap;
                store.push(content);
            });
            increment++;
        });

        setLoading(true);
        updateRoadMap(store).then((response) => {
            setLoading(false);
            message(response.messages, response.status);
        });
    }

    function openDetail(detail) {
        let data = {};
        if(detail){
            data = detail
        }
        setDetail(data);
        setIsOpenDetail(!isOpenDetail);
    }

    const alertDetail = () => {
        message(['Debe Iniciar la entrega en el local para visualizar los detalles de la orden'], false);
    }

    function beginDelivery(codeStore){
        if(!storeCodeActive){
            setLoading(true);
            getBagControl(codeStore, idHeaderRoadMap, freightNumber, 1).then((response) => {
                setLoading(false);
                if(response.status){
                    setMessageAlert("¿Esta seguro que dese iniciar la entrega del restaurante "+codeStore+ "?");
                    setItem(codeStore);
                    setShowAlert(true);
                    storeCodeActive = codeStore;
                } else {
                    message(['Debe diligenciar el formulario de jabas para el local '+codeStore+' para poder continuar'], response.status);
                }
                
            });
            
        } else {
            message(['La entrega en la tienda '+storeCodeActive+' ya se encuentra activa debe finalizarla antes de continuar'], false); 
        }
    }

    function message(messages, type){
        let icon = (type) ? checkmarkCircleOutline : closeCircle;
        let color = (type) ? 'success' : 'secondary';
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: color,
                icon: icon
            })
        ));
    }

    const printBody = () => {
        storeCodeActive = '';
        const rowBody = data.map((item, key) => (
            <IonAccordion value={item} key={key} toggleIcon={caretDownCircle}>
                <IonItem slot="header">
                    <IonReorder><b>Restaurante {item} #</b> {key+1}</IonReorder>
                </IonItem >
                <div className="ion-padding" slot="content">
                    <IonRow className={styles.acorddionRow}>
                    <IonCol size='6'>
                        <IonButton onClick={()=>openAlert('beginDelivery', item)}>  
                            <IonIcon className={styles.ionIcon} icon={arrowForwardOutline}/>
                            {item}
                        </IonButton> 
                    </IonCol>
                    <IonCol size='3' align='right'>
                        <IonButton className={styles.btnMap} onClick={()=>openJabaForm(item)}>  
                            <IonIcon  icon={receiptOutline} ></IonIcon>
                        </IonButton>
                    </IonCol>
                    <IonCol size='3' align='right'>
                        <IonButton className={styles.btnMap} onClick={()=>openInfo(item)}>  
                            <IonIcon  icon={storefront} ></IonIcon>
                        </IonButton>
                    </IonCol>
                    </IonRow>
                    <IonList>
                    {
                        details[item].map((detail, key) => {
                        if(detail.status === '2'){
                            storeCodeActive = item;
                            return (
                                <IonItem className={styles.detail} button detail={true}  key={ key } onClick={()=>openDetail(detail)}> 
                                    <IonRow>
                                        <IonCol size="12">
                                        {
                                        headers.map((header, keyD) => {
                                        return (
                                            <label key={keyD}>
                                                <b>{header.text}</b> {detail[header.value]} <br></br>
                                            </label>
                                        );})
                                        }
                                        </IonCol>
                                    </IonRow>
                                </IonItem>
                            );
                        } else {
                            return (
                                <IonItem key={ key } className={styles.rowItem} button onClick={()=>alertDetail()}> 
                                    <IonRow>
                                        <IonCol size="12">
                                        {
                                        headers.map((header, keyD) => {
                                        return (
                                            <label key={keyD}>
                                                <b>{header.text}</b> {detail[header.value]} <br></br>
                                            </label>
                                        );})
                                        }
                                        </IonCol>
                                    </IonRow>
                                </IonItem>
                            );
                        }})
                    }
                    </IonList>
                </div>
            </IonAccordion>
        ));

        return rowBody;
    }

    function recharge(){
        setCount(0);
    }

    function openJabaForm(codeStore){
        viewJabaForm(codeStore, idHeaderRoadMap, freightNumber, 1);
    }
    
    return (
        <div className={ styles.resultHeader }>
            <IonLoading isOpen={loading} message="Cargando..." />
            <fieldset>
            <legend>Hoja de Ruta</legend>
            <IonRow>
                <IonCol size='4' align='right'>
                    <IonButton onClick={()=>viewHeaderRoadMap()} className={styles.btnSearch}>  
                        <IonIcon icon={clipboard}></IonIcon>
                    </IonButton>
                </IonCol>
                <IonCol size="4">
                    <IonButton onClick={()=>openAlert('updateOrder')} className={styles.btnSearch}>  
                        <IonIcon icon={bus}></IonIcon>
                    </IonButton>
                </IonCol>
                <IonCol size="4">
                    <IonButton onClick={()=>recharge()} className={styles.btnSearch}>  
                        <IonIcon icon={reload}></IonIcon>
                    </IonButton>
                </IonCol>
            </IonRow>
            <div className={ styles.contentRoadMap }>
                <IonAccordionGroup>
                    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
                            {printBody()}
                    </IonReorderGroup>
                </IonAccordionGroup>
            </div>
            <ModalInfoRestaurant open={isOpenInfo} fn={openInfo} idStore={idStore} />
            <ModalDetailRoadMap open={isOpenDetail} fn={openDetail} content={detail} />
            </fieldset>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={messageAlert}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Aceptar",
                        handler: () => {
                            execute();
                        }
                    }
                ]}
            />
        </div>
    );
};

export default ListRoadMap;