import { IonRow, IonCol, IonSelect, IonSelectOption, IonItem } from "@ionic/react";
import { useEffect, useState } from "react";
import styles from "../Css/CustomPaginatorShort.module.scss";

const CustomPaginatorShort = ({ pags, to, actualPage, fn }) => {
  const [items, setItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(actualPage);
  const calculate = () => {
    if (pags !== 0 && to !== 0) {
      const total = pags / parseInt(to);
      let list = [];
      for (let index = 0; index < total; index++) {
        list.push(index + 1);
        
      }
     setItems(list);
    }
  };

  useEffect(() => {  
    calculate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {  
    calculate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pags]);
 
  useEffect(() => {
    setSelectedPage(actualPage);
  }, [actualPage]);

  const handlePageChange = (value) => {
    setSelectedPage(value);
    fn(value);
  };

  const printPages = () => {
    const pages = items.map((item) => (
      <IonSelectOption value={item} key={item}>
        {item}
      </IonSelectOption>
    ));
    return pages;
  };

  return (
    <div className={styles.fieldPs}>
      <IonRow>
        <IonCol size="auto">
          <IonItem className={styles.itemPs}>
            <IonSelect
              interface="popover"
              className={styles.paginatorPs}
              onIonChange={(e) => handlePageChange(e.detail.value)}
              value={selectedPage} // Utiliza selectedPage para reflejar el valor seleccionado
            >
              {printPages()}
            </IonSelect>
          </IonItem>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default CustomPaginatorShort;


