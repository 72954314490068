import React, { useState, useEffect } from "react";
import {
  IonIcon,
  IonButton,
  IonList,
  IonAccordion,
  IonItem,
  IonRow,
  IonCol,
  IonAccordionGroup
} from "@ionic/react";
import {
  refreshOutline,
  caretDownCircle,
  receiptOutline
} from "ionicons/icons";
import styles from "../Css/List.module.scss";
import { getHeaderRoadMap } from '../Services';

const List = ({
  details,
  headers,
  freightNumber,
  viewDetail,
  viewJabaForm,
}) => {
  const [listFreightNumber, setListFreightNumber] = useState([]);
  const [listDetails, setListDetails] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setListFreightNumber(freightNumber);
    setListDetails(details);
  }, [details, freightNumber]);

 useEffect(() => {
    if (count <= 6) {
      const interval = setInterval(() => {
        getHeaderRoadMap().then((response) => {
          if(JSON.stringify(listDetails) !== JSON.stringify(response.data) 
            && details.length === undefined){
            setListDetails(response.data);
            setListFreightNumber(response.freightNumber);
          }
        });
        setCount(count + 1);
      }, 10000);
      return () => clearInterval(interval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  function openJabaForm(item){
    viewJabaForm(listDetails[item][0].destinationStore, listDetails[item][0].idHeaderRoadMap, item);
  }

  function recharge(){
    setCount(0);
}

  function openDetail(detail){
    viewDetail(detail.idGuide, detail.idDetailRoadMap);
  }

  const printBody = () => {
    const rowBody = listFreightNumber.map((item, key) => (
      <IonAccordion value={item} key={key} toggleIcon={caretDownCircle}>
        <IonItem slot="header">
          <b>Código de Flete # {item} </b>
        </IonItem >
        <div className="ion-padding" slot="content">
          <IonRow className={styles.acorddionRow}>
            <IonCol align='right'>
              <IonButton className={styles.btnMap} onClick={()=>openJabaForm(item)}>  
                <IonIcon  icon={receiptOutline} ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonList>
            {
              listDetails[item].map((detail, key) => {
              if(detail.status === '2'){
                return (
                  <IonItem className={styles.detail} button detail={true}  key={ key } onClick={()=>openDetail(detail)}> 
                    <IonRow>
                      <IonCol size="12">
                        {
                          headers.map((header, keyD) => {
                            return (
                              <label key={keyD}>
                                <b>{header.text}</b> {detail[header.value]} <br></br>
                              </label>
                            );})
                        }
                      </IonCol>
                    </IonRow>
                  </IonItem>
                );
              } else {
                return (
                  <IonItem key={ key } className={styles.rowItem} button> 
                    <IonRow>
                      <IonCol size="12">
                        {
                          headers.map((header, keyD) => {
                            return (
                              <label key={keyD}>
                                <b>{header.text}</b> {detail[header.value]} <br></br>
                              </label>
                            );})
                        }
                      </IonCol>
                    </IonRow>
                  </IonItem>
                );
              }})
            }
          </IonList>
        </div>
      </IonAccordion>
   ));

    return rowBody;
  };

  return (
    <div>
      <div className={styles.resultHeader}>
        <fieldset>
          <legend>Guías de Remisión</legend>
          <div className={styles.content}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <IonButton onClick={recharge}>
                <IonIcon icon={refreshOutline} />
              </IonButton>
            </div>
            <ion-item-divider>
              <ion-label> Resultados de búsqueda </ion-label>
            </ion-item-divider>
            <div className={ styles.contentRoadMap }>
              <IonAccordionGroup>
                {printBody()}
              </IonAccordionGroup>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default List;
