import React from "react";
import { Route, useHistory } from "react-router-dom";

export const AuthRouteComponent = (props) => {
  const token_seguridad = sessionStorage.getItem('token');
  const history = useHistory();

  React.useEffect(() => {
    if (!token_seguridad) {
      history.push("/");
    }
  }, [props?.location?.pathname, history, token_seguridad]);

  return <Route {...props} />;
};
